@charset "UTF-8";
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #d81f26;
  --bs-orange: #ea630f;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #777777;
  --bs-gray-dark: #929292;
  --bs-gray-100: #f1f1f1;
  --bs-gray-200: #ebebeb;
  --bs-gray-300: #c0c0c0;
  --bs-gray-400: #e2e2e2;
  --bs-gray-500: #808080;
  --bs-gray-600: #777777;
  --bs-gray-700: #495057;
  --bs-gray-800: #929292;
  --bs-gray-900: #666666;
  --bs-primary: #00a19c;
  --bs-primary-70: #75c4c2;
  --bs-primary-50: #80d0ce;
  --bs-primary-30: #b3e3e2;
  --bs-primary-10: #e6f6f6;
  --bs-secondary: #222328;
  --bs-secondary-80: #4e4f53;
  --bs-addition-color-100: #CED4C7;
  --bs-addition-color-70: #EDE0DF;
  --bs-addition-color-50: #B7C9D7;
  --bs-addition-color-30: #E9E2D0;
  --bs-addition-color-10: #665344;
  --bs-light: #f5eae1;
  --bs-success: #3ea06b;
  --bs-footer: #eae1d6;
  --bs-brown: #665244;
  --bs-gray-1: #C6BEB3;
  --bs-gray-10: #ede0df;
  --bs-gray-20: #ced4c7;
  --bs-gray-30: #C6BEB1;
  --bs-gray-100: #f1f1f1;
  --bs-gray-200: #ebebeb;
  --bs-gray-300: #c0c0c0;
  --bs-gray-400: #e2e2e2;
  --bs-gray-500: #808080;
  --bs-gray-600: #777777;
  --bs-gray-800: #929292;
  --bs-gray-900: #666666;
  --bs-body: #000;
  --bs-info: #21aaff;
  --bs-warning: #ffc600;
  --bs-danger: #d81f26;
  --bs-dark: #cccccc;
  --bs-black: #000000;
  --bs-red: #d81f26;
  --bs-orange: #ea630f;
  --bs-white: #ffffff;
  --bs-bold: #222328;
  --bs-body-bold: #665344;
  --bs-body-all: #fff6ea;
  --bs-primary-rgb: 0, 161, 156;
  --bs-primary-70-rgb: 117, 196, 194;
  --bs-primary-50-rgb: 128, 208, 206;
  --bs-primary-30-rgb: 179, 227, 226;
  --bs-primary-10-rgb: 230, 246, 246;
  --bs-secondary-rgb: 34, 35, 40;
  --bs-secondary-80-rgb: 78, 79, 83;
  --bs-addition-color-100-rgb: 206, 212, 199;
  --bs-addition-color-70-rgb: 237, 224, 223;
  --bs-addition-color-50-rgb: 183, 201, 215;
  --bs-addition-color-30-rgb: 233, 226, 208;
  --bs-addition-color-10-rgb: 102, 83, 68;
  --bs-light-rgb: 245, 234, 225;
  --bs-success-rgb: 62, 160, 107;
  --bs-footer-rgb: 234, 225, 214;
  --bs-brown-rgb: 102, 82, 68;
  --bs-gray-1-rgb: 198, 190, 179;
  --bs-gray-10-rgb: 237, 224, 223;
  --bs-gray-20-rgb: 206, 212, 199;
  --bs-gray-30-rgb: 198, 190, 177;
  --bs-gray-100-rgb: 241, 241, 241;
  --bs-gray-200-rgb: 235, 235, 235;
  --bs-gray-300-rgb: 192, 192, 192;
  --bs-gray-400-rgb: 226, 226, 226;
  --bs-gray-500-rgb: 128, 128, 128;
  --bs-gray-600-rgb: 119, 119, 119;
  --bs-gray-800-rgb: 146, 146, 146;
  --bs-gray-900-rgb: 102, 102, 102;
  --bs-body-rgb: 0, 0, 0;
  --bs-info-rgb: 33, 170, 255;
  --bs-warning-rgb: 255, 198, 0;
  --bs-danger-rgb: 216, 31, 38;
  --bs-dark-rgb: 204, 204, 204;
  --bs-black-rgb: 0, 0, 0;
  --bs-red-rgb: 216, 31, 38;
  --bs-orange-rgb: 234, 99, 15;
  --bs-white-rgb: 255, 255, 255;
  --bs-bold-rgb: 34, 35, 40;
  --bs-body-bold-rgb: 102, 83, 68;
  --bs-body-all-rgb: 255, 246, 234;
  --bs-primary-text-emphasis: #00403e;
  --bs-secondary-text-emphasis: #0e0e10;
  --bs-success-text-emphasis: #19402b;
  --bs-info-text-emphasis: #0d4466;
  --bs-warning-text-emphasis: #664f00;
  --bs-danger-text-emphasis: #560c0f;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cceceb;
  --bs-secondary-bg-subtle: #d3d3d4;
  --bs-success-bg-subtle: #d8ece1;
  --bs-info-bg-subtle: #d3eeff;
  --bs-warning-bg-subtle: #fff4cc;
  --bs-danger-bg-subtle: #f7d2d4;
  --bs-light-bg-subtle: #f8f8f8;
  --bs-dark-bg-subtle: #e2e2e2;
  --bs-primary-border-subtle: #99d9d7;
  --bs-secondary-border-subtle: #a7a7a9;
  --bs-success-border-subtle: #b2d9c4;
  --bs-info-border-subtle: #a6ddff;
  --bs-warning-border-subtle: #ffe899;
  --bs-danger-border-subtle: #efa5a8;
  --bs-light-border-subtle: #ebebeb;
  --bs-dark-border-subtle: #808080;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "SFProDisplay", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: calc(1.2625rem + 0.15vw);
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(0, 0, 0, 0.75);
  --bs-secondary-color-rgb: 0, 0, 0;
  --bs-secondary-bg: #ebebeb;
  --bs-secondary-bg-rgb: 235, 235, 235;
  --bs-tertiary-color: rgba(0, 0, 0, 0.5);
  --bs-tertiary-color-rgb: 0, 0, 0;
  --bs-tertiary-bg: #f1f1f1;
  --bs-tertiary-bg-rgb: 241, 241, 241;
  --bs-heading-color: inherit;
  --bs-link-color: #00a19c;
  --bs-link-color-rgb: 0, 161, 156;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #00817d;
  --bs-link-hover-color-rgb: 0, 129, 125;
  --bs-code-color: #d63384;
  --bs-highlight-color: #000;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d6d6d6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 4px 8px rgba(0, 0, 0, 0.16);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 161, 156, 0.25);
  --bs-form-valid-color: #3ea06b;
  --bs-form-valid-border-color: #3ea06b;
  --bs-form-invalid-color: #d81f26;
  --bs-form-invalid-border-color: #d81f26;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #c0c0c0;
  --bs-body-color-rgb: 192, 192, 192;
  --bs-body-bg: #666666;
  --bs-body-bg-rgb: 102, 102, 102;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(192, 192, 192, 0.75);
  --bs-secondary-color-rgb: 192, 192, 192;
  --bs-secondary-bg: #929292;
  --bs-secondary-bg-rgb: 146, 146, 146;
  --bs-tertiary-color: rgba(192, 192, 192, 0.5);
  --bs-tertiary-color-rgb: 192, 192, 192;
  --bs-tertiary-bg: #7c7c7c;
  --bs-tertiary-bg-rgb: 124, 124, 124;
  --bs-primary-text-emphasis: #66c7c4;
  --bs-secondary-text-emphasis: #7a7b7e;
  --bs-success-text-emphasis: #8bc6a6;
  --bs-info-text-emphasis: #7accff;
  --bs-warning-text-emphasis: #ffdd66;
  --bs-danger-text-emphasis: #e8797d;
  --bs-light-text-emphasis: #f1f1f1;
  --bs-dark-text-emphasis: #c0c0c0;
  --bs-primary-bg-subtle: #00201f;
  --bs-secondary-bg-subtle: #070708;
  --bs-success-bg-subtle: #0c2015;
  --bs-info-bg-subtle: #072233;
  --bs-warning-bg-subtle: #332800;
  --bs-danger-bg-subtle: #2b0608;
  --bs-light-bg-subtle: #929292;
  --bs-dark-bg-subtle: #494949;
  --bs-primary-border-subtle: #00615e;
  --bs-secondary-border-subtle: #141518;
  --bs-success-border-subtle: #256040;
  --bs-info-border-subtle: #146699;
  --bs-warning-border-subtle: #997700;
  --bs-danger-border-subtle: #821317;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #929292;
  --bs-heading-color: inherit;
  --bs-link-color: #66c7c4;
  --bs-link-hover-color: #85d2d0;
  --bs-link-color-rgb: 102, 199, 196;
  --bs-link-hover-color-rgb: 133, 210, 208;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #c0c0c0;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #e8797d;
  --bs-form-invalid-border-color: #e8797d;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.6667rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.33335rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.5375rem + 3.45vw);
}

h2 {
  font-size: calc(1.4rem + 1.8vw);
}

h3 {
  font-size: calc(1.3484375rem + 1.18125vw);
}

h4 {
  font-size: calc(1.33125rem + 0.975vw);
}

h5 {
  font-size: calc(1.296875rem + 0.5625vw);
}

h6 {
  font-size: calc(1.2796875rem + 0.35625vw);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0.9375rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 2.133rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.33335rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.33335rem;
}

.g-3px,
.gx-3px {
  --bs-gutter-x: 3px;
}

.g-3px,
.gy-3px {
  --bs-gutter-y: 3px;
}

.g-15px,
.gx-15px {
  --bs-gutter-x: 15px;
}

.g-15px,
.gy-15px {
  --bs-gutter-y: 15px;
}

.g-25px,
.gx-25px {
  --bs-gutter-x: 25px;
}

.g-25px,
.gy-25px {
  --bs-gutter-y: 25px;
}

.g-35px,
.gx-35px {
  --bs-gutter-x: 35px;
}

.g-35px,
.gy-35px {
  --bs-gutter-y: 35px;
}

.g-700,
.gx-700 {
  --bs-gutter-x: 700px;
}

.g-700,
.gy-700 {
  --bs-gutter-y: 700px;
}

.g-n15,
.gx-n15 {
  --bs-gutter-x: -15px;
}

.g-n15,
.gy-n15 {
  --bs-gutter-y: -15px;
}

.g-n1,
.gx-n1 {
  --bs-gutter-x: -10px;
}

.g-n1,
.gy-n1 {
  --bs-gutter-y: -10px;
}

.g-n5,
.gx-n5 {
  --bs-gutter-x: -5px;
}

.g-n5,
.gy-n5 {
  --bs-gutter-y: -5px;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6667rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6667rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.3334rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.3334rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.0001rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.0001rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.6668rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.6668rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.3335rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.3335rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.0002rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.0002rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.6669rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.6669rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5.3336rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5.3336rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6.0003rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6.0003rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6.667rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6.667rem;
}

.g-gutter,
.gx-gutter {
  --bs-gutter-x: 2.133rem;
}

.g-gutter,
.gy-gutter {
  --bs-gutter-y: 2.133rem;
}

.g-out-container,
.gx-out-container {
  --bs-gutter-x: calc(50% - 50vw);
}

.g-out-container,
.gy-out-container {
  --bs-gutter-y: calc(50% - 50vw);
}
.font-sans-serif {
  font-family: var(--bs-font-sans-serif) !important;
}

.font-sans-serif-italic {
  font-family: var(--bs-font-sans-serif-italic) !important;
}

.font-sans-serif-lightItalic {
  font-family: var(--bs-font-sans-serif-lightItalic) !important;
}

.font-sans-serif-bold {
  font-family: var(--bs-font-sans-serif-bold) !important;
}

.font-sans-serif-semibold {
  font-family: var(--bs-font-sans-serif-semibold) !important;
}

.font-sans-serif-gegular {
  font-family: var(--bs-font-sans-serif-gegular) !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semi {
  font-weight: 600 !important;
}

.fs-8 {
  font-size: 4rem !important;
}

.fs-9 {
  font-size: 1.5rem !important;
}

.fs-10 {
  font-size: 1.375rem !important;
}

.fs-11 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 1.25rem !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 0.875rem !important;
}

.fs-17 {
  font-size: 0.625rem !important;
}

.fs-18 {
  font-size: 0.375rem !important;
}

.fs-19 {
  font-size: 0.75rem !important;
}

.fs-20 {
  font-size: 2.375rem !important;
}

.fs-40px {
  font-size: 2.5rem !important;
}

.fs-32px {
  font-size: 2rem !important;
}

.fs-48px {
  font-size: 3rem !important;
}

.fs-13px {
  font-size: 13px !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.bg-primary {
  background-color: #00a19c !important;
}

.bg-primary-70 {
  background-color: #75c4c2 !important;
}

.bg-primary-50 {
  background-color: #80d0ce !important;
}

.bg-primary-30 {
  background-color: #b3e3e2 !important;
}

.bg-primary-10 {
  background-color: #e6f6f6 !important;
}

.bg-secondary {
  background-color: #222328 !important;
}

.bg-secondary-80 {
  background-color: #4e4f53 !important;
}

.bg-addition-color-100 {
  background-color: #CED4C7 !important;
}

.bg-addition-color-70 {
  background-color: #EDE0DF !important;
}

.bg-addition-color-50 {
  background-color: #B7C9D7 !important;
}

.bg-addition-color-30 {
  background-color: #E9E2D0 !important;
}

.bg-addition-color-10 {
  background-color: #665344 !important;
}

.bg-light {
  background-color: #f5eae1 !important;
}

.bg-success {
  background-color: #3ea06b !important;
}

.bg-footer {
  background-color: #eae1d6 !important;
}

.bg-brown {
  background-color: #665244 !important;
}

.bg-gray-1 {
  background-color: #C6BEB3 !important;
}

.bg-gray-10 {
  background-color: #ede0df !important;
}

.bg-gray-20 {
  background-color: #ced4c7 !important;
}

.bg-gray-30 {
  background-color: #C6BEB1 !important;
}

.bg-gray-100 {
  background-color: #f1f1f1 !important;
}

.bg-gray-200 {
  background-color: #ebebeb !important;
}

.bg-gray-300 {
  background-color: #c0c0c0 !important;
}

.bg-gray-400 {
  background-color: #e2e2e2 !important;
}

.bg-gray-500 {
  background-color: #808080 !important;
}

.bg-gray-600 {
  background-color: #777777 !important;
}

.bg-gray-800 {
  background-color: #929292 !important;
}

.bg-gray-900 {
  background-color: #666666 !important;
}

.bg-body {
  background-color: #000 !important;
}

.bg-info {
  background-color: #21aaff !important;
}

.bg-warning {
  background-color: #ffc600 !important;
}

.bg-danger {
  background-color: #d81f26 !important;
}

.bg-dark {
  background-color: #cccccc !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-red {
  background-color: #d81f26 !important;
}

.bg-orange {
  background-color: #ea630f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-bold {
  background-color: #222328 !important;
}

.bg-body-bold {
  background-color: #665344 !important;
}

.bg-body-all {
  background-color: #fff6ea !important;
}

.text-primary {
  color: #00a19c !important;
}

.text-primary-70 {
  color: #75c4c2 !important;
}

.text-primary-50 {
  color: #80d0ce !important;
}

.text-primary-30 {
  color: #b3e3e2 !important;
}

.text-primary-10 {
  color: #e6f6f6 !important;
}

.text-secondary {
  color: #222328 !important;
}

.text-secondary-80 {
  color: #4e4f53 !important;
}

.text-addition-color-100 {
  color: #CED4C7 !important;
}

.text-addition-color-70 {
  color: #EDE0DF !important;
}

.text-addition-color-50 {
  color: #B7C9D7 !important;
}

.text-addition-color-30 {
  color: #E9E2D0 !important;
}

.text-addition-color-10 {
  color: #665344 !important;
}

.text-light {
  color: #f5eae1 !important;
}

.text-success {
  color: #3ea06b !important;
}

.text-footer {
  color: #eae1d6 !important;
}

.text-brown {
  color: #665244 !important;
}

.text-gray-1 {
  color: #C6BEB3 !important;
}

.text-gray-10 {
  color: #ede0df !important;
}

.text-gray-20 {
  color: #ced4c7 !important;
}

.text-gray-30 {
  color: #C6BEB1 !important;
}

.text-gray-100 {
  color: #f1f1f1 !important;
}

.text-gray-200 {
  color: #ebebeb !important;
}

.text-gray-300 {
  color: #c0c0c0 !important;
}

.text-gray-400 {
  color: #e2e2e2 !important;
}

.text-gray-500 {
  color: #808080 !important;
}

.text-gray-600 {
  color: #777777 !important;
}

.text-gray-800 {
  color: #929292 !important;
}

.text-gray-900 {
  color: #666666 !important;
}

.text-body {
  color: #000 !important;
}

.text-info {
  color: #21aaff !important;
}

.text-warning {
  color: #ffc600 !important;
}

.text-danger {
  color: #d81f26 !important;
}

.text-dark {
  color: #cccccc !important;
}

.text-black {
  color: #000000 !important;
}

.text-red {
  color: #d81f26 !important;
}

.text-orange {
  color: #ea630f !important;
}

.text-white {
  color: #ffffff !important;
}

.text-bold {
  color: #222328 !important;
}

.text-body-bold {
  color: #665344 !important;
}

.text-body-all {
  color: #fff6ea !important;
}

.m-sm {
  margin: 0.33335rem !important;
}

.m-3px {
  margin: 3px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-35px {
  margin: 35px !important;
}

.m-700 {
  margin: 700px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n1 {
  margin: -10px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.6667rem !important;
}

.m-2 {
  margin: 1.3334rem !important;
}

.m-3 {
  margin: 2.0001rem !important;
}

.m-4 {
  margin: 2.6668rem !important;
}

.m-5 {
  margin: 3.3335rem !important;
}

.m-6 {
  margin: 4.0002rem !important;
}

.m-7 {
  margin: 4.6669rem !important;
}

.m-8 {
  margin: 5.3336rem !important;
}

.m-9 {
  margin: 6.0003rem !important;
}

.m-10 {
  margin: 6.667rem !important;
}

.m-gutter {
  margin: 2.133rem !important;
}

.m-out-container {
  margin: calc(50% - 50vw) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-sm {
  margin-right: 0.33335rem !important;
  margin-left: 0.33335rem !important;
}

.mx-3px {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-15px {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-25px {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-35px {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-700 {
  margin-right: 700px !important;
  margin-left: 700px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n1 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.6667rem !important;
  margin-left: 0.6667rem !important;
}

.mx-2 {
  margin-right: 1.3334rem !important;
  margin-left: 1.3334rem !important;
}

.mx-3 {
  margin-right: 2.0001rem !important;
  margin-left: 2.0001rem !important;
}

.mx-4 {
  margin-right: 2.6668rem !important;
  margin-left: 2.6668rem !important;
}

.mx-5 {
  margin-right: 3.3335rem !important;
  margin-left: 3.3335rem !important;
}

.mx-6 {
  margin-right: 4.0002rem !important;
  margin-left: 4.0002rem !important;
}

.mx-7 {
  margin-right: 4.6669rem !important;
  margin-left: 4.6669rem !important;
}

.mx-8 {
  margin-right: 5.3336rem !important;
  margin-left: 5.3336rem !important;
}

.mx-9 {
  margin-right: 6.0003rem !important;
  margin-left: 6.0003rem !important;
}

.mx-10 {
  margin-right: 6.667rem !important;
  margin-left: 6.667rem !important;
}

.mx-gutter {
  margin-right: 2.133rem !important;
  margin-left: 2.133rem !important;
}

.mx-out-container {
  margin-right: calc(50% - 50vw) !important;
  margin-left: calc(50% - 50vw) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm {
  margin-top: 0.33335rem !important;
  margin-bottom: 0.33335rem !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-700 {
  margin-top: 700px !important;
  margin-bottom: 700px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n1 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.6667rem !important;
  margin-bottom: 0.6667rem !important;
}

.my-2 {
  margin-top: 1.3334rem !important;
  margin-bottom: 1.3334rem !important;
}

.my-3 {
  margin-top: 2.0001rem !important;
  margin-bottom: 2.0001rem !important;
}

.my-4 {
  margin-top: 2.6668rem !important;
  margin-bottom: 2.6668rem !important;
}

.my-5 {
  margin-top: 3.3335rem !important;
  margin-bottom: 3.3335rem !important;
}

.my-6 {
  margin-top: 4.0002rem !important;
  margin-bottom: 4.0002rem !important;
}

.my-7 {
  margin-top: 4.6669rem !important;
  margin-bottom: 4.6669rem !important;
}

.my-8 {
  margin-top: 5.3336rem !important;
  margin-bottom: 5.3336rem !important;
}

.my-9 {
  margin-top: 6.0003rem !important;
  margin-bottom: 6.0003rem !important;
}

.my-10 {
  margin-top: 6.667rem !important;
  margin-bottom: 6.667rem !important;
}

.my-gutter {
  margin-top: 2.133rem !important;
  margin-bottom: 2.133rem !important;
}

.my-out-container {
  margin-top: calc(50% - 50vw) !important;
  margin-bottom: calc(50% - 50vw) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm {
  margin-top: 0.33335rem !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-700 {
  margin-top: 700px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n1 {
  margin-top: -10px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.6667rem !important;
}

.mt-2 {
  margin-top: 1.3334rem !important;
}

.mt-3 {
  margin-top: 2.0001rem !important;
}

.mt-4 {
  margin-top: 2.6668rem !important;
}

.mt-5 {
  margin-top: 3.3335rem !important;
}

.mt-6 {
  margin-top: 4.0002rem !important;
}

.mt-7 {
  margin-top: 4.6669rem !important;
}

.mt-8 {
  margin-top: 5.3336rem !important;
}

.mt-9 {
  margin-top: 6.0003rem !important;
}

.mt-10 {
  margin-top: 6.667rem !important;
}

.mt-gutter {
  margin-top: 2.133rem !important;
}

.mt-out-container {
  margin-top: calc(50% - 50vw) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-140px {
  margin-top: 140px !important;
}

.me-sm {
  margin-right: 0.33335rem !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-35px {
  margin-right: 35px !important;
}

.me-700 {
  margin-right: 700px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n1 {
  margin-right: -10px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.6667rem !important;
}

.me-2 {
  margin-right: 1.3334rem !important;
}

.me-3 {
  margin-right: 2.0001rem !important;
}

.me-4 {
  margin-right: 2.6668rem !important;
}

.me-5 {
  margin-right: 3.3335rem !important;
}

.me-6 {
  margin-right: 4.0002rem !important;
}

.me-7 {
  margin-right: 4.6669rem !important;
}

.me-8 {
  margin-right: 5.3336rem !important;
}

.me-9 {
  margin-right: 6.0003rem !important;
}

.me-10 {
  margin-right: 6.667rem !important;
}

.me-gutter {
  margin-right: 2.133rem !important;
}

.me-out-container {
  margin-right: calc(50% - 50vw) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-sm {
  margin-bottom: 0.33335rem !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-700 {
  margin-bottom: 700px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n1 {
  margin-bottom: -10px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.6667rem !important;
}

.mb-2 {
  margin-bottom: 1.3334rem !important;
}

.mb-3 {
  margin-bottom: 2.0001rem !important;
}

.mb-4 {
  margin-bottom: 2.6668rem !important;
}

.mb-5 {
  margin-bottom: 3.3335rem !important;
}

.mb-6 {
  margin-bottom: 4.0002rem !important;
}

.mb-7 {
  margin-bottom: 4.6669rem !important;
}

.mb-8 {
  margin-bottom: 5.3336rem !important;
}

.mb-9 {
  margin-bottom: 6.0003rem !important;
}

.mb-10 {
  margin-bottom: 6.667rem !important;
}

.mb-gutter {
  margin-bottom: 2.133rem !important;
}

.mb-out-container {
  margin-bottom: calc(50% - 50vw) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-sm {
  margin-left: 0.33335rem !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-35px {
  margin-left: 35px !important;
}

.ms-700 {
  margin-left: 700px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n1 {
  margin-left: -10px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.6667rem !important;
}

.ms-2 {
  margin-left: 1.3334rem !important;
}

.ms-3 {
  margin-left: 2.0001rem !important;
}

.ms-4 {
  margin-left: 2.6668rem !important;
}

.ms-5 {
  margin-left: 3.3335rem !important;
}

.ms-6 {
  margin-left: 4.0002rem !important;
}

.ms-7 {
  margin-left: 4.6669rem !important;
}

.ms-8 {
  margin-left: 5.3336rem !important;
}

.ms-9 {
  margin-left: 6.0003rem !important;
}

.ms-10 {
  margin-left: 6.667rem !important;
}

.ms-gutter {
  margin-left: 2.133rem !important;
}

.ms-out-container {
  margin-left: calc(50% - 50vw) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-sm {
  padding: 0.33335rem !important;
}

.p-3px {
  padding: 3px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-700 {
  padding: 700px !important;
}

.p-n15 {
  padding: -15px !important;
}

.p-n1 {
  padding: -10px !important;
}

.p-n5 {
  padding: -5px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.6667rem !important;
}

.p-2 {
  padding: 1.3334rem !important;
}

.p-3 {
  padding: 2.0001rem !important;
}

.p-4 {
  padding: 2.6668rem !important;
}

.p-5 {
  padding: 3.3335rem !important;
}

.p-6 {
  padding: 4.0002rem !important;
}

.p-7 {
  padding: 4.6669rem !important;
}

.p-8 {
  padding: 5.3336rem !important;
}

.p-9 {
  padding: 6.0003rem !important;
}

.p-10 {
  padding: 6.667rem !important;
}

.p-gutter {
  padding: 2.133rem !important;
}

.p-out-container {
  padding: calc(50% - 50vw) !important;
}

.px-sm {
  padding-right: 0.33335rem !important;
  padding-left: 0.33335rem !important;
}

.px-3px {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-15px {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-25px {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-35px {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.px-700 {
  padding-right: 700px !important;
  padding-left: 700px !important;
}

.px-n15 {
  padding-right: -15px !important;
  padding-left: -15px !important;
}

.px-n1 {
  padding-right: -10px !important;
  padding-left: -10px !important;
}

.px-n5 {
  padding-right: -5px !important;
  padding-left: -5px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}

.px-2 {
  padding-right: 1.3334rem !important;
  padding-left: 1.3334rem !important;
}

.px-3 {
  padding-right: 2.0001rem !important;
  padding-left: 2.0001rem !important;
}

.px-4 {
  padding-right: 2.6668rem !important;
  padding-left: 2.6668rem !important;
}

.px-5 {
  padding-right: 3.3335rem !important;
  padding-left: 3.3335rem !important;
}

.px-6 {
  padding-right: 4.0002rem !important;
  padding-left: 4.0002rem !important;
}

.px-7 {
  padding-right: 4.6669rem !important;
  padding-left: 4.6669rem !important;
}

.px-8 {
  padding-right: 5.3336rem !important;
  padding-left: 5.3336rem !important;
}

.px-9 {
  padding-right: 6.0003rem !important;
  padding-left: 6.0003rem !important;
}

.px-10 {
  padding-right: 6.667rem !important;
  padding-left: 6.667rem !important;
}

.px-gutter {
  padding-right: 2.133rem !important;
  padding-left: 2.133rem !important;
}

.px-out-container {
  padding-right: calc(50% - 50vw) !important;
  padding-left: calc(50% - 50vw) !important;
}

.py-sm {
  padding-top: 0.33335rem !important;
  padding-bottom: 0.33335rem !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-700 {
  padding-top: 700px !important;
  padding-bottom: 700px !important;
}

.py-n15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.py-n1 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.py-n5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.6667rem !important;
  padding-bottom: 0.6667rem !important;
}

.py-2 {
  padding-top: 1.3334rem !important;
  padding-bottom: 1.3334rem !important;
}

.py-3 {
  padding-top: 2.0001rem !important;
  padding-bottom: 2.0001rem !important;
}

.py-4 {
  padding-top: 2.6668rem !important;
  padding-bottom: 2.6668rem !important;
}

.py-5 {
  padding-top: 3.3335rem !important;
  padding-bottom: 3.3335rem !important;
}

.py-6 {
  padding-top: 4.0002rem !important;
  padding-bottom: 4.0002rem !important;
}

.py-7 {
  padding-top: 4.6669rem !important;
  padding-bottom: 4.6669rem !important;
}

.py-8 {
  padding-top: 5.3336rem !important;
  padding-bottom: 5.3336rem !important;
}

.py-9 {
  padding-top: 6.0003rem !important;
  padding-bottom: 6.0003rem !important;
}

.py-10 {
  padding-top: 6.667rem !important;
  padding-bottom: 6.667rem !important;
}

.py-gutter {
  padding-top: 2.133rem !important;
  padding-bottom: 2.133rem !important;
}

.py-out-container {
  padding-top: calc(50% - 50vw) !important;
  padding-bottom: calc(50% - 50vw) !important;
}

.pt-sm {
  padding-top: 0.33335rem !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-700 {
  padding-top: 700px !important;
}

.pt-n15 {
  padding-top: -15px !important;
}

.pt-n1 {
  padding-top: -10px !important;
}

.pt-n5 {
  padding-top: -5px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.6667rem !important;
}

.pt-2 {
  padding-top: 1.3334rem !important;
}

.pt-3 {
  padding-top: 2.0001rem !important;
}

.pt-4 {
  padding-top: 2.6668rem !important;
}

.pt-5 {
  padding-top: 3.3335rem !important;
}

.pt-6 {
  padding-top: 4.0002rem !important;
}

.pt-7 {
  padding-top: 4.6669rem !important;
}

.pt-8 {
  padding-top: 5.3336rem !important;
}

.pt-9 {
  padding-top: 6.0003rem !important;
}

.pt-10 {
  padding-top: 6.667rem !important;
}

.pt-gutter {
  padding-top: 2.133rem !important;
}

.pt-out-container {
  padding-top: calc(50% - 50vw) !important;
}

.pe-sm {
  padding-right: 0.33335rem !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-35px {
  padding-right: 35px !important;
}

.pe-700 {
  padding-right: 700px !important;
}

.pe-n15 {
  padding-right: -15px !important;
}

.pe-n1 {
  padding-right: -10px !important;
}

.pe-n5 {
  padding-right: -5px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.6667rem !important;
}

.pe-2 {
  padding-right: 1.3334rem !important;
}

.pe-3 {
  padding-right: 2.0001rem !important;
}

.pe-4 {
  padding-right: 2.6668rem !important;
}

.pe-5 {
  padding-right: 3.3335rem !important;
}

.pe-6 {
  padding-right: 4.0002rem !important;
}

.pe-7 {
  padding-right: 4.6669rem !important;
}

.pe-8 {
  padding-right: 5.3336rem !important;
}

.pe-9 {
  padding-right: 6.0003rem !important;
}

.pe-10 {
  padding-right: 6.667rem !important;
}

.pe-gutter {
  padding-right: 2.133rem !important;
}

.pe-out-container {
  padding-right: calc(50% - 50vw) !important;
}

.pb-sm {
  padding-bottom: 0.33335rem !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-700 {
  padding-bottom: 700px !important;
}

.pb-n15 {
  padding-bottom: -15px !important;
}

.pb-n1 {
  padding-bottom: -10px !important;
}

.pb-n5 {
  padding-bottom: -5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.6667rem !important;
}

.pb-2 {
  padding-bottom: 1.3334rem !important;
}

.pb-3 {
  padding-bottom: 2.0001rem !important;
}

.pb-4 {
  padding-bottom: 2.6668rem !important;
}

.pb-5 {
  padding-bottom: 3.3335rem !important;
}

.pb-6 {
  padding-bottom: 4.0002rem !important;
}

.pb-7 {
  padding-bottom: 4.6669rem !important;
}

.pb-8 {
  padding-bottom: 5.3336rem !important;
}

.pb-9 {
  padding-bottom: 6.0003rem !important;
}

.pb-10 {
  padding-bottom: 6.667rem !important;
}

.pb-gutter {
  padding-bottom: 2.133rem !important;
}

.pb-out-container {
  padding-bottom: calc(50% - 50vw) !important;
}

.ps-sm {
  padding-left: 0.33335rem !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-35px {
  padding-left: 35px !important;
}

.ps-700 {
  padding-left: 700px !important;
}

.ps-n15 {
  padding-left: -15px !important;
}

.ps-n1 {
  padding-left: -10px !important;
}

.ps-n5 {
  padding-left: -5px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.6667rem !important;
}

.ps-2 {
  padding-left: 1.3334rem !important;
}

.ps-3 {
  padding-left: 2.0001rem !important;
}

.ps-4 {
  padding-left: 2.6668rem !important;
}

.ps-5 {
  padding-left: 3.3335rem !important;
}

.ps-6 {
  padding-left: 4.0002rem !important;
}

.ps-7 {
  padding-left: 4.6669rem !important;
}

.ps-8 {
  padding-left: 5.3336rem !important;
}

.ps-9 {
  padding-left: 6.0003rem !important;
}

.ps-10 {
  padding-left: 6.667rem !important;
}

.ps-gutter {
  padding-left: 2.133rem !important;
}

.ps-out-container {
  padding-left: calc(50% - 50vw) !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-sm {
  gap: 0.33335rem !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-15px {
  gap: 15px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-35px {
  gap: 35px !important;
}

.gap-700 {
  gap: 700px !important;
}

.gap-n15 {
  gap: -15px !important;
}

.gap-n1 {
  gap: -10px !important;
}

.gap-n5 {
  gap: -5px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.6667rem !important;
}

.gap-2 {
  gap: 1.3334rem !important;
}

.gap-3 {
  gap: 2.0001rem !important;
}

.gap-4 {
  gap: 2.6668rem !important;
}

.gap-5 {
  gap: 3.3335rem !important;
}

.gap-6 {
  gap: 4.0002rem !important;
}

.gap-7 {
  gap: 4.6669rem !important;
}

.gap-8 {
  gap: 5.3336rem !important;
}

.gap-9 {
  gap: 6.0003rem !important;
}

.gap-10 {
  gap: 6.667rem !important;
}

.gap-gutter {
  gap: 2.133rem !important;
}

.gap-out-container {
  gap: calc(50% - 50vw) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-25 {
  opacity: 0.25 !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.o-70 {
  opacity: 0.7 !important;
}

.o-75 {
  opacity: 0.75 !important;
}

.o-85 {
  opacity: 0.85 !important;
}

.o-100 {
  opacity: 1 !important;
}

.z-1 {
  z-index: 9 !important;
}

.z-2 {
  z-index: 99 !important;
}

.z-3 {
  z-index: 999 !important;
}

.z-4 {
  z-index: 1039 !important;
}

.z-5 {
  z-index: 9999 !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-full {
  -o-object-fit: full !important;
     object-fit: full !important;
}

.object-position-top {
  -o-object-position: top !important;
     object-position: top !important;
}

.object-position-center {
  -o-object-position: center !important;
     object-position: center !important;
}

.object-position-bottom {
  -o-object-position: bottom !important;
     object-position: bottom !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20px {
  width: 20px !important;
}

.w-80px {
  width: 80px !important;
}

.w-180 {
  width: 180px !important;
}

.w-200px {
  width: 200px !important;
}

.w-cal200 {
  width: calc(100% - 200px) !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-300 {
  max-width: 300px !important;
}

.mw-824 {
  max-width: 824px !important;
}

.mw-890 {
  max-width: 890px !important;
}

.mw-1100 {
  max-width: 1100px !important;
}

.mw-1410 {
  max-width: 1410px !important;
}

.minw-270 {
  min-width: 270px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-20px {
  height: 20px !important;
}

.h-27px {
  height: 27px !important;
}

.h-472px {
  height: 472px !important;
}

.h-200px {
  height: 200px !important;
}

.h-600px {
  height: 600px !important;
}

.h-auto {
  height: auto !important;
}

.mh-490 {
  max-height: 490px !important;
}

.mh-550 {
  max-height: 550px !important;
}

.mh-432 {
  max-height: 432px !important;
}

.transform-none {
  transform: none !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-md {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.order-unset {
  order: unset !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.border {
  border: 1px solid #d6d6d6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d6d6d6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-top-2 {
  border-top: 2px solid !important;
}

.border-start {
  border-left: 1px solid #d6d6d6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-end {
  border-right: 1px solid #d6d6d6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d6d6d6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-primary {
  border-color: #00a19c !important;
}

.border-primary-70 {
  border-color: #75c4c2 !important;
}

.border-primary-50 {
  border-color: #80d0ce !important;
}

.border-primary-30 {
  border-color: #b3e3e2 !important;
}

.border-primary-10 {
  border-color: #e6f6f6 !important;
}

.border-secondary {
  border-color: #222328 !important;
}

.border-secondary-80 {
  border-color: #4e4f53 !important;
}

.border-addition-color-100 {
  border-color: #CED4C7 !important;
}

.border-addition-color-70 {
  border-color: #EDE0DF !important;
}

.border-addition-color-50 {
  border-color: #B7C9D7 !important;
}

.border-addition-color-30 {
  border-color: #E9E2D0 !important;
}

.border-addition-color-10 {
  border-color: #665344 !important;
}

.border-light {
  border-color: #f5eae1 !important;
}

.border-success {
  border-color: #3ea06b !important;
}

.border-footer {
  border-color: #eae1d6 !important;
}

.border-brown {
  border-color: #665244 !important;
}

.border-gray-1 {
  border-color: #C6BEB3 !important;
}

.border-gray-10 {
  border-color: #ede0df !important;
}

.border-gray-20 {
  border-color: #ced4c7 !important;
}

.border-gray-30 {
  border-color: #C6BEB1 !important;
}

.border-gray-100 {
  border-color: #f1f1f1 !important;
}

.border-gray-200 {
  border-color: #ebebeb !important;
}

.border-gray-300 {
  border-color: #c0c0c0 !important;
}

.border-gray-400 {
  border-color: #e2e2e2 !important;
}

.border-gray-500 {
  border-color: #808080 !important;
}

.border-gray-600 {
  border-color: #777777 !important;
}

.border-gray-800 {
  border-color: #929292 !important;
}

.border-gray-900 {
  border-color: #666666 !important;
}

.border-body {
  border-color: #000 !important;
}

.border-info {
  border-color: #21aaff !important;
}

.border-warning {
  border-color: #ffc600 !important;
}

.border-danger {
  border-color: #d81f26 !important;
}

.border-dark {
  border-color: #cccccc !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-red {
  border-color: #d81f26 !important;
}

.border-orange {
  border-color: #ea630f !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-bold {
  border-color: #222328 !important;
}

.border-body-bold {
  border-color: #665344 !important;
}

.border-body-all {
  border-color: #fff6ea !important;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-30 {
  top: 30px !important;
}

.top-n10 {
  top: -10px !important;
}

.top-n20 {
  top: -20px !important;
}

.top-n8 {
  top: -8px !important;
}

.top-7 {
  top: 7px !important;
}

.top-13 {
  top: 13px !important;
}

.top-15 {
  top: 15px !important;
}

.top-n3 {
  top: -3px !important;
}

.top-5 {
  top: 5px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.bottom-n10 {
  bottom: -10px !important;
}

.bottom-n20 {
  bottom: -20px !important;
}

.bottom-n8 {
  bottom: -8px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-n3 {
  bottom: -3px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-10 {
  left: 10px !important;
}

.start-20 {
  left: 20px !important;
}

.start-30 {
  left: 30px !important;
}

.start-n10 {
  left: -10px !important;
}

.start-n20 {
  left: -20px !important;
}

.start-n8 {
  left: -8px !important;
}

.start-7 {
  left: 7px !important;
}

.start-13 {
  left: 13px !important;
}

.start-15 {
  left: 15px !important;
}

.start-n3 {
  left: -3px !important;
}

.start-5 {
  left: 5px !important;
}

.start-2 {
  left: 2px !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-10 {
  right: 10px !important;
}

.end-20 {
  right: 20px !important;
}

.end-30 {
  right: 30px !important;
}

.end-n10 {
  right: -10px !important;
}

.end-n20 {
  right: -20px !important;
}

.end-n8 {
  right: -8px !important;
}

.end-7 {
  right: 7px !important;
}

.end-13 {
  right: 13px !important;
}

.end-15 {
  right: 15px !important;
}

.end-n3 {
  right: -3px !important;
}

.end-5 {
  right: 5px !important;
}

.end-2 {
  right: 2px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.pevents-none {
  pointer-events: none !important;
}

.pevents-auto {
  pointer-events: auto !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #00a19c;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.33335rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: -0.40234375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 2.578125rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 2.578125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: calc(1.2625rem + 0.15vw);
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, .footer .map .overlay_button a {
  --bs-btn-color: #000000;
  --bs-btn-bg: #00a19c;
  --bs-btn-border-color: #00a19c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #26afab;
  --bs-btn-hover-border-color: #1aaaa6;
  --bs-btn-focus-shadow-rgb: 0, 137, 133;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #33b4b0;
  --bs-btn-active-border-color: #1aaaa6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #00a19c;
  --bs-btn-disabled-border-color: #00a19c;
}

.btn-primary-70 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #75c4c2;
  --bs-btn-border-color: #75c4c2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #8acdcb;
  --bs-btn-hover-border-color: #83cac8;
  --bs-btn-focus-shadow-rgb: 99, 167, 165;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #91d0ce;
  --bs-btn-active-border-color: #83cac8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #75c4c2;
  --bs-btn-disabled-border-color: #75c4c2;
}

.btn-primary-50 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #80d0ce;
  --bs-btn-border-color: #80d0ce;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #93d7d5;
  --bs-btn-hover-border-color: #8dd5d3;
  --bs-btn-focus-shadow-rgb: 109, 177, 175;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #99d9d8;
  --bs-btn-active-border-color: #8dd5d3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #80d0ce;
  --bs-btn-disabled-border-color: #80d0ce;
}

.btn-primary-30 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #b3e3e2;
  --bs-btn-border-color: #b3e3e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #bee7e6;
  --bs-btn-hover-border-color: #bbe6e5;
  --bs-btn-focus-shadow-rgb: 152, 193, 192;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c2e9e8;
  --bs-btn-active-border-color: #bbe6e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #b3e3e2;
  --bs-btn-disabled-border-color: #b3e3e2;
}

.btn-primary-10 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e6f6f6;
  --bs-btn-border-color: #e6f6f6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eaf7f7;
  --bs-btn-hover-border-color: #e9f7f7;
  --bs-btn-focus-shadow-rgb: 196, 209, 209;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ebf8f8;
  --bs-btn-active-border-color: #e9f7f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e6f6f6;
  --bs-btn-disabled-border-color: #e6f6f6;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #222328;
  --bs-btn-border-color: #222328;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1d1e22;
  --bs-btn-hover-border-color: #1b1c20;
  --bs-btn-focus-shadow-rgb: 67, 68, 72;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b1c20;
  --bs-btn-active-border-color: #1a1a1e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #222328;
  --bs-btn-disabled-border-color: #222328;
}

.btn-secondary-80 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4e4f53;
  --bs-btn-border-color: #4e4f53;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424347;
  --bs-btn-hover-border-color: #3e3f42;
  --bs-btn-focus-shadow-rgb: 105, 105, 109;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3e3f42;
  --bs-btn-active-border-color: #3b3b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4e4f53;
  --bs-btn-disabled-border-color: #4e4f53;
}

.btn-addition-color-100 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #CED4C7;
  --bs-btn-border-color: #CED4C7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d5dacf;
  --bs-btn-hover-border-color: #d3d8cd;
  --bs-btn-focus-shadow-rgb: 175, 180, 169;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d8ddd2;
  --bs-btn-active-border-color: #d3d8cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #CED4C7;
  --bs-btn-disabled-border-color: #CED4C7;
}

.btn-addition-color-70 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #EDE0DF;
  --bs-btn-border-color: #EDE0DF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f0e5e4;
  --bs-btn-hover-border-color: #efe3e2;
  --bs-btn-focus-shadow-rgb: 201, 190, 190;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f1e6e5;
  --bs-btn-active-border-color: #efe3e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #EDE0DF;
  --bs-btn-disabled-border-color: #EDE0DF;
}

.btn-addition-color-50 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #B7C9D7;
  --bs-btn-border-color: #B7C9D7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c2d1dd;
  --bs-btn-hover-border-color: #becedb;
  --bs-btn-focus-shadow-rgb: 156, 171, 183;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c5d4df;
  --bs-btn-active-border-color: #becedb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #B7C9D7;
  --bs-btn-disabled-border-color: #B7C9D7;
}

.btn-addition-color-30 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #E9E2D0;
  --bs-btn-border-color: #E9E2D0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ece6d7;
  --bs-btn-hover-border-color: #ebe5d5;
  --bs-btn-focus-shadow-rgb: 198, 192, 177;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ede8d9;
  --bs-btn-active-border-color: #ebe5d5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #E9E2D0;
  --bs-btn-disabled-border-color: #E9E2D0;
}

.btn-addition-color-10 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #665344;
  --bs-btn-border-color: #665344;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #57473a;
  --bs-btn-hover-border-color: #524236;
  --bs-btn-focus-shadow-rgb: 125, 109, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #524236;
  --bs-btn-active-border-color: #4d3e33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #665344;
  --bs-btn-disabled-border-color: #665344;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f5eae1;
  --bs-btn-border-color: #f5eae1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d0c7bf;
  --bs-btn-hover-border-color: #c4bbb4;
  --bs-btn-focus-shadow-rgb: 208, 199, 191;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c4bbb4;
  --bs-btn-active-border-color: #b8b0a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f5eae1;
  --bs-btn-disabled-border-color: #f5eae1;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #3ea06b;
  --bs-btn-border-color: #3ea06b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5bae81;
  --bs-btn-hover-border-color: #51aa7a;
  --bs-btn-focus-shadow-rgb: 53, 136, 91;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #65b389;
  --bs-btn-active-border-color: #51aa7a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #3ea06b;
  --bs-btn-disabled-border-color: #3ea06b;
}

.btn-footer {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eae1d6;
  --bs-btn-border-color: #eae1d6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ede6dc;
  --bs-btn-hover-border-color: #ece4da;
  --bs-btn-focus-shadow-rgb: 199, 191, 182;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eee7de;
  --bs-btn-active-border-color: #ece4da;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eae1d6;
  --bs-btn-disabled-border-color: #eae1d6;
}

.btn-brown {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #665244;
  --bs-btn-border-color: #665244;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #57463a;
  --bs-btn-hover-border-color: #524236;
  --bs-btn-focus-shadow-rgb: 125, 108, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #524236;
  --bs-btn-active-border-color: #4d3e33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #665244;
  --bs-btn-disabled-border-color: #665244;
}

.btn-gray-1 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #C6BEB3;
  --bs-btn-border-color: #C6BEB3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cfc8be;
  --bs-btn-hover-border-color: #ccc5bb;
  --bs-btn-focus-shadow-rgb: 168, 162, 152;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d1cbc2;
  --bs-btn-active-border-color: #ccc5bb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #C6BEB3;
  --bs-btn-disabled-border-color: #C6BEB3;
}

.btn-gray-10 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ede0df;
  --bs-btn-border-color: #ede0df;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f0e5e4;
  --bs-btn-hover-border-color: #efe3e2;
  --bs-btn-focus-shadow-rgb: 201, 190, 190;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f1e6e5;
  --bs-btn-active-border-color: #efe3e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ede0df;
  --bs-btn-disabled-border-color: #ede0df;
}

.btn-gray-20 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ced4c7;
  --bs-btn-border-color: #ced4c7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d5dacf;
  --bs-btn-hover-border-color: #d3d8cd;
  --bs-btn-focus-shadow-rgb: 175, 180, 169;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d8ddd2;
  --bs-btn-active-border-color: #d3d8cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ced4c7;
  --bs-btn-disabled-border-color: #ced4c7;
}

.btn-gray-30 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #C6BEB1;
  --bs-btn-border-color: #C6BEB1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cfc8bd;
  --bs-btn-hover-border-color: #ccc5b9;
  --bs-btn-focus-shadow-rgb: 168, 162, 150;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d1cbc1;
  --bs-btn-active-border-color: #ccc5b9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #C6BEB1;
  --bs-btn-disabled-border-color: #C6BEB1;
}

.btn-gray-100 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f1f1f1;
  --bs-btn-border-color: #f1f1f1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f3f3f3;
  --bs-btn-hover-border-color: #f2f2f2;
  --bs-btn-focus-shadow-rgb: 205, 205, 205;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f4f4f4;
  --bs-btn-active-border-color: #f2f2f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f1f1f1;
  --bs-btn-disabled-border-color: #f1f1f1;
}

.btn-gray-200 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ebebeb;
  --bs-btn-border-color: #ebebeb;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eeeeee;
  --bs-btn-hover-border-color: #ededed;
  --bs-btn-focus-shadow-rgb: 200, 200, 200;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #efefef;
  --bs-btn-active-border-color: #ededed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ebebeb;
  --bs-btn-disabled-border-color: #ebebeb;
}

.btn-gray-300 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #c0c0c0;
  --bs-btn-border-color: #c0c0c0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c9c9c9;
  --bs-btn-hover-border-color: #c6c6c6;
  --bs-btn-focus-shadow-rgb: 163, 163, 163;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cdcdcd;
  --bs-btn-active-border-color: #c6c6c6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #c0c0c0;
  --bs-btn-disabled-border-color: #c0c0c0;
}

.btn-gray-400 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #e5e5e5;
  --bs-btn-focus-shadow-rgb: 192, 192, 192;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e8e8e8;
  --bs-btn-active-border-color: #e5e5e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e2e2e2;
  --bs-btn-disabled-border-color: #e2e2e2;
}

.btn-gray-500 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #808080;
  --bs-btn-border-color: #808080;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #939393;
  --bs-btn-hover-border-color: #8d8d8d;
  --bs-btn-focus-shadow-rgb: 109, 109, 109;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #999999;
  --bs-btn-active-border-color: #8d8d8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #808080;
  --bs-btn-disabled-border-color: #808080;
}

.btn-gray-600 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #777777;
  --bs-btn-border-color: #777777;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #8b8b8b;
  --bs-btn-hover-border-color: #858585;
  --bs-btn-focus-shadow-rgb: 101, 101, 101;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #929292;
  --bs-btn-active-border-color: #858585;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #777777;
  --bs-btn-disabled-border-color: #777777;
}

.btn-gray-800 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #929292;
  --bs-btn-border-color: #929292;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a2a2a2;
  --bs-btn-hover-border-color: #9d9d9d;
  --bs-btn-focus-shadow-rgb: 124, 124, 124;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a8a8a8;
  --bs-btn-active-border-color: #9d9d9d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #929292;
  --bs-btn-disabled-border-color: #929292;
}

.btn-gray-900 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #575757;
  --bs-btn-hover-border-color: #525252;
  --bs-btn-focus-shadow-rgb: 125, 125, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #525252;
  --bs-btn-active-border-color: #4d4d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #666666;
  --bs-btn-disabled-border-color: #666666;
}

.btn-body {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #21aaff;
  --bs-btn-border-color: #21aaff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #42b7ff;
  --bs-btn-hover-border-color: #37b3ff;
  --bs-btn-focus-shadow-rgb: 28, 145, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4dbbff;
  --bs-btn-active-border-color: #37b3ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #21aaff;
  --bs-btn-disabled-border-color: #21aaff;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffc600;
  --bs-btn-border-color: #ffc600;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffcf26;
  --bs-btn-hover-border-color: #ffcc1a;
  --bs-btn-focus-shadow-rgb: 217, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd133;
  --bs-btn-active-border-color: #ffcc1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffc600;
  --bs-btn-disabled-border-color: #ffc600;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d81f26;
  --bs-btn-border-color: #d81f26;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b81a20;
  --bs-btn-hover-border-color: #ad191e;
  --bs-btn-focus-shadow-rgb: 222, 65, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ad191e;
  --bs-btn-active-border-color: #a2171d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d81f26;
  --bs-btn-disabled-border-color: #d81f26;
}

.btn-dark {
  --bs-btn-color: #000000;
  --bs-btn-bg: #cccccc;
  --bs-btn-border-color: #cccccc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d4d4d4;
  --bs-btn-hover-border-color: #d1d1d1;
  --bs-btn-focus-shadow-rgb: 173, 173, 173;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d6d6d6;
  --bs-btn-active-border-color: #d1d1d1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #cccccc;
  --bs-btn-disabled-border-color: #cccccc;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-red {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d81f26;
  --bs-btn-border-color: #d81f26;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b81a20;
  --bs-btn-hover-border-color: #ad191e;
  --bs-btn-focus-shadow-rgb: 222, 65, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ad191e;
  --bs-btn-active-border-color: #a2171d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d81f26;
  --bs-btn-disabled-border-color: #d81f26;
}

.btn-orange {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ea630f;
  --bs-btn-border-color: #ea630f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ed7a33;
  --bs-btn-hover-border-color: #ec7327;
  --bs-btn-focus-shadow-rgb: 199, 84, 13;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ee823f;
  --bs-btn-active-border-color: #ec7327;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ea630f;
  --bs-btn-disabled-border-color: #ea630f;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-bold {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #222328;
  --bs-btn-border-color: #222328;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1d1e22;
  --bs-btn-hover-border-color: #1b1c20;
  --bs-btn-focus-shadow-rgb: 67, 68, 72;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b1c20;
  --bs-btn-active-border-color: #1a1a1e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #222328;
  --bs-btn-disabled-border-color: #222328;
}

.btn-body-bold {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #665344;
  --bs-btn-border-color: #665344;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #57473a;
  --bs-btn-hover-border-color: #524236;
  --bs-btn-focus-shadow-rgb: 125, 109, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #524236;
  --bs-btn-active-border-color: #4d3e33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #665344;
  --bs-btn-disabled-border-color: #665344;
}

.btn-body-all {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fff6ea;
  --bs-btn-border-color: #fff6ea;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fff7ed;
  --bs-btn-hover-border-color: #fff7ec;
  --bs-btn-focus-shadow-rgb: 217, 209, 199;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fff8ee;
  --bs-btn-active-border-color: #fff7ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fff6ea;
  --bs-btn-disabled-border-color: #fff6ea;
}

.btn-outline-primary {
  --bs-btn-color: #00a19c;
  --bs-btn-border-color: #00a19c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #00a19c;
  --bs-btn-hover-border-color: #00a19c;
  --bs-btn-focus-shadow-rgb: 0, 161, 156;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #00a19c;
  --bs-btn-active-border-color: #00a19c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00a19c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00a19c;
  --bs-gradient: none;
}

.btn-outline-primary-70 {
  --bs-btn-color: #75c4c2;
  --bs-btn-border-color: #75c4c2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #75c4c2;
  --bs-btn-hover-border-color: #75c4c2;
  --bs-btn-focus-shadow-rgb: 117, 196, 194;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #75c4c2;
  --bs-btn-active-border-color: #75c4c2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #75c4c2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #75c4c2;
  --bs-gradient: none;
}

.btn-outline-primary-50 {
  --bs-btn-color: #80d0ce;
  --bs-btn-border-color: #80d0ce;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #80d0ce;
  --bs-btn-hover-border-color: #80d0ce;
  --bs-btn-focus-shadow-rgb: 128, 208, 206;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #80d0ce;
  --bs-btn-active-border-color: #80d0ce;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #80d0ce;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #80d0ce;
  --bs-gradient: none;
}

.btn-outline-primary-30 {
  --bs-btn-color: #b3e3e2;
  --bs-btn-border-color: #b3e3e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #b3e3e2;
  --bs-btn-hover-border-color: #b3e3e2;
  --bs-btn-focus-shadow-rgb: 179, 227, 226;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b3e3e2;
  --bs-btn-active-border-color: #b3e3e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #b3e3e2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b3e3e2;
  --bs-gradient: none;
}

.btn-outline-primary-10 {
  --bs-btn-color: #e6f6f6;
  --bs-btn-border-color: #e6f6f6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e6f6f6;
  --bs-btn-hover-border-color: #e6f6f6;
  --bs-btn-focus-shadow-rgb: 230, 246, 246;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e6f6f6;
  --bs-btn-active-border-color: #e6f6f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e6f6f6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e6f6f6;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #222328;
  --bs-btn-border-color: #222328;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #222328;
  --bs-btn-hover-border-color: #222328;
  --bs-btn-focus-shadow-rgb: 34, 35, 40;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #222328;
  --bs-btn-active-border-color: #222328;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222328;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #222328;
  --bs-gradient: none;
}

.btn-outline-secondary-80 {
  --bs-btn-color: #4e4f53;
  --bs-btn-border-color: #4e4f53;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4e4f53;
  --bs-btn-hover-border-color: #4e4f53;
  --bs-btn-focus-shadow-rgb: 78, 79, 83;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4e4f53;
  --bs-btn-active-border-color: #4e4f53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4e4f53;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4e4f53;
  --bs-gradient: none;
}

.btn-outline-addition-color-100 {
  --bs-btn-color: #CED4C7;
  --bs-btn-border-color: #CED4C7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #CED4C7;
  --bs-btn-hover-border-color: #CED4C7;
  --bs-btn-focus-shadow-rgb: 206, 212, 199;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #CED4C7;
  --bs-btn-active-border-color: #CED4C7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #CED4C7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #CED4C7;
  --bs-gradient: none;
}

.btn-outline-addition-color-70 {
  --bs-btn-color: #EDE0DF;
  --bs-btn-border-color: #EDE0DF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #EDE0DF;
  --bs-btn-hover-border-color: #EDE0DF;
  --bs-btn-focus-shadow-rgb: 237, 224, 223;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #EDE0DF;
  --bs-btn-active-border-color: #EDE0DF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EDE0DF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EDE0DF;
  --bs-gradient: none;
}

.btn-outline-addition-color-50 {
  --bs-btn-color: #B7C9D7;
  --bs-btn-border-color: #B7C9D7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #B7C9D7;
  --bs-btn-hover-border-color: #B7C9D7;
  --bs-btn-focus-shadow-rgb: 183, 201, 215;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #B7C9D7;
  --bs-btn-active-border-color: #B7C9D7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #B7C9D7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B7C9D7;
  --bs-gradient: none;
}

.btn-outline-addition-color-30 {
  --bs-btn-color: #E9E2D0;
  --bs-btn-border-color: #E9E2D0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #E9E2D0;
  --bs-btn-hover-border-color: #E9E2D0;
  --bs-btn-focus-shadow-rgb: 233, 226, 208;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #E9E2D0;
  --bs-btn-active-border-color: #E9E2D0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E9E2D0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E9E2D0;
  --bs-gradient: none;
}

.btn-outline-addition-color-10 {
  --bs-btn-color: #665344;
  --bs-btn-border-color: #665344;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #665344;
  --bs-btn-hover-border-color: #665344;
  --bs-btn-focus-shadow-rgb: 102, 83, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #665344;
  --bs-btn-active-border-color: #665344;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #665344;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #665344;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f5eae1;
  --bs-btn-border-color: #f5eae1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f5eae1;
  --bs-btn-hover-border-color: #f5eae1;
  --bs-btn-focus-shadow-rgb: 245, 234, 225;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f5eae1;
  --bs-btn-active-border-color: #f5eae1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5eae1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5eae1;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3ea06b;
  --bs-btn-border-color: #3ea06b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3ea06b;
  --bs-btn-hover-border-color: #3ea06b;
  --bs-btn-focus-shadow-rgb: 62, 160, 107;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3ea06b;
  --bs-btn-active-border-color: #3ea06b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3ea06b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3ea06b;
  --bs-gradient: none;
}

.btn-outline-footer {
  --bs-btn-color: #eae1d6;
  --bs-btn-border-color: #eae1d6;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eae1d6;
  --bs-btn-hover-border-color: #eae1d6;
  --bs-btn-focus-shadow-rgb: 234, 225, 214;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eae1d6;
  --bs-btn-active-border-color: #eae1d6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eae1d6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eae1d6;
  --bs-gradient: none;
}

.btn-outline-brown {
  --bs-btn-color: #665244;
  --bs-btn-border-color: #665244;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #665244;
  --bs-btn-hover-border-color: #665244;
  --bs-btn-focus-shadow-rgb: 102, 82, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #665244;
  --bs-btn-active-border-color: #665244;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #665244;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #665244;
  --bs-gradient: none;
}

.btn-outline-gray-1 {
  --bs-btn-color: #C6BEB3;
  --bs-btn-border-color: #C6BEB3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #C6BEB3;
  --bs-btn-hover-border-color: #C6BEB3;
  --bs-btn-focus-shadow-rgb: 198, 190, 179;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #C6BEB3;
  --bs-btn-active-border-color: #C6BEB3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C6BEB3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C6BEB3;
  --bs-gradient: none;
}

.btn-outline-gray-10 {
  --bs-btn-color: #ede0df;
  --bs-btn-border-color: #ede0df;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ede0df;
  --bs-btn-hover-border-color: #ede0df;
  --bs-btn-focus-shadow-rgb: 237, 224, 223;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ede0df;
  --bs-btn-active-border-color: #ede0df;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ede0df;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ede0df;
  --bs-gradient: none;
}

.btn-outline-gray-20 {
  --bs-btn-color: #ced4c7;
  --bs-btn-border-color: #ced4c7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ced4c7;
  --bs-btn-hover-border-color: #ced4c7;
  --bs-btn-focus-shadow-rgb: 206, 212, 199;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ced4c7;
  --bs-btn-active-border-color: #ced4c7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ced4c7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ced4c7;
  --bs-gradient: none;
}

.btn-outline-gray-30 {
  --bs-btn-color: #C6BEB1;
  --bs-btn-border-color: #C6BEB1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #C6BEB1;
  --bs-btn-hover-border-color: #C6BEB1;
  --bs-btn-focus-shadow-rgb: 198, 190, 177;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #C6BEB1;
  --bs-btn-active-border-color: #C6BEB1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C6BEB1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C6BEB1;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #f1f1f1;
  --bs-btn-border-color: #f1f1f1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f1f1f1;
  --bs-btn-hover-border-color: #f1f1f1;
  --bs-btn-focus-shadow-rgb: 241, 241, 241;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f1f1f1;
  --bs-btn-active-border-color: #f1f1f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1f1f1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1f1f1;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #ebebeb;
  --bs-btn-border-color: #ebebeb;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ebebeb;
  --bs-btn-hover-border-color: #ebebeb;
  --bs-btn-focus-shadow-rgb: 235, 235, 235;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ebebeb;
  --bs-btn-active-border-color: #ebebeb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ebebeb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ebebeb;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #c0c0c0;
  --bs-btn-border-color: #c0c0c0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #c0c0c0;
  --bs-btn-hover-border-color: #c0c0c0;
  --bs-btn-focus-shadow-rgb: 192, 192, 192;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c0c0c0;
  --bs-btn-active-border-color: #c0c0c0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c0c0c0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c0c0c0;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e2e2e2;
  --bs-btn-hover-border-color: #e2e2e2;
  --bs-btn-focus-shadow-rgb: 226, 226, 226;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e2e2e2;
  --bs-btn-active-border-color: #e2e2e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e2e2e2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e2e2e2;
  --bs-gradient: none;
}

.btn-outline-gray-500 {
  --bs-btn-color: #808080;
  --bs-btn-border-color: #808080;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #808080;
  --bs-btn-hover-border-color: #808080;
  --bs-btn-focus-shadow-rgb: 128, 128, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #808080;
  --bs-btn-active-border-color: #808080;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #808080;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #808080;
  --bs-gradient: none;
}

.btn-outline-gray-600 {
  --bs-btn-color: #777777;
  --bs-btn-border-color: #777777;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #777777;
  --bs-btn-hover-border-color: #777777;
  --bs-btn-focus-shadow-rgb: 119, 119, 119;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #777777;
  --bs-btn-active-border-color: #777777;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #777777;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #777777;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #929292;
  --bs-btn-border-color: #929292;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #929292;
  --bs-btn-hover-border-color: #929292;
  --bs-btn-focus-shadow-rgb: 146, 146, 146;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #929292;
  --bs-btn-active-border-color: #929292;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #929292;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #929292;
  --bs-gradient: none;
}

.btn-outline-gray-900 {
  --bs-btn-color: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #666666;
  --bs-btn-hover-border-color: #666666;
  --bs-btn-focus-shadow-rgb: 102, 102, 102;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #666666;
  --bs-btn-active-border-color: #666666;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #666666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #666666;
  --bs-gradient: none;
}

.btn-outline-body {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #21aaff;
  --bs-btn-border-color: #21aaff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #21aaff;
  --bs-btn-hover-border-color: #21aaff;
  --bs-btn-focus-shadow-rgb: 33, 170, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #21aaff;
  --bs-btn-active-border-color: #21aaff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #21aaff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #21aaff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc600;
  --bs-btn-border-color: #ffc600;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffc600;
  --bs-btn-hover-border-color: #ffc600;
  --bs-btn-focus-shadow-rgb: 255, 198, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffc600;
  --bs-btn-active-border-color: #ffc600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc600;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #d81f26;
  --bs-btn-border-color: #d81f26;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d81f26;
  --bs-btn-hover-border-color: #d81f26;
  --bs-btn-focus-shadow-rgb: 216, 31, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d81f26;
  --bs-btn-active-border-color: #d81f26;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d81f26;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d81f26;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #cccccc;
  --bs-btn-border-color: #cccccc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cccccc;
  --bs-btn-hover-border-color: #cccccc;
  --bs-btn-focus-shadow-rgb: 204, 204, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cccccc;
  --bs-btn-active-border-color: #cccccc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #cccccc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cccccc;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #d81f26;
  --bs-btn-border-color: #d81f26;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d81f26;
  --bs-btn-hover-border-color: #d81f26;
  --bs-btn-focus-shadow-rgb: 216, 31, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d81f26;
  --bs-btn-active-border-color: #d81f26;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d81f26;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d81f26;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #ea630f;
  --bs-btn-border-color: #ea630f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ea630f;
  --bs-btn-hover-border-color: #ea630f;
  --bs-btn-focus-shadow-rgb: 234, 99, 15;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ea630f;
  --bs-btn-active-border-color: #ea630f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ea630f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea630f;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-bold {
  --bs-btn-color: #222328;
  --bs-btn-border-color: #222328;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #222328;
  --bs-btn-hover-border-color: #222328;
  --bs-btn-focus-shadow-rgb: 34, 35, 40;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #222328;
  --bs-btn-active-border-color: #222328;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222328;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #222328;
  --bs-gradient: none;
}

.btn-outline-body-bold {
  --bs-btn-color: #665344;
  --bs-btn-border-color: #665344;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #665344;
  --bs-btn-hover-border-color: #665344;
  --bs-btn-focus-shadow-rgb: 102, 83, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #665344;
  --bs-btn-active-border-color: #665344;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #665344;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #665344;
  --bs-gradient: none;
}

.btn-outline-body-all {
  --bs-btn-color: #fff6ea;
  --bs-btn-border-color: #fff6ea;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fff6ea;
  --bs-btn-hover-border-color: #fff6ea;
  --bs-btn-focus-shadow-rgb: 255, 246, 234;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fff6ea;
  --bs-btn-active-border-color: #fff6ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff6ea;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff6ea;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #777777;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 0, 137, 133;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.3828125rem + 1.59375vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 1.203125rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 0.6667rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 0.6667rem;
  --bs-modal-header-padding-y: 0.6667rem;
  --bs-modal-header-padding: 0.6667rem 0.6667rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(1.3828125rem + 1.59375vw);
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 1.203125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #80d0ce;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 1.203125rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.3828125rem + 1.59375vw);
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23929292' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #80d0ce;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.203125rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3828125rem + 1.59375vw);
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23c0c0c0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 2.0625rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #80d0ce;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.form-check-input:checked {
  background-color: #21aaff;
  border-color: #21aaff;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #00a19c;
  border-color: #00a19c;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380d0ce'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #00a19c;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3e3e1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #00a19c;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3e3e1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #777777;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3828125rem + 1.59375vw);
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.203125rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 1.203125rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 1.203125rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d81f26'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d81f26' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d81f26'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d81f26' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300403e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #80d0ce;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 161, 156, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: calc(1.2625rem + 0.15vw);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366c7c4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366c7c4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

header .header {
  position: relative;
}
header .container.pt-lg-2 {
  transition: 0.2s;
}
header.affix .container.pt-lg-2 {
  padding-top: 5px !important;
}
header.affix .main_menu #menu-main-menu > li > a {
  padding: 10px 0;
}
header.affix .submenu_hover {
  top: 42px;
}
header.affix #total-items {
  top: -16px;
}

.main_menu {
  position: relative;
}
.main_menu ul > li {
  padding: 0 14px;
}
.main_menu ul > li > a {
  letter-spacing: 1px;
  display: block;
}

.parent_menu {
  padding: 10px 30px !important;
}

.navbar-header {
  display: none;
}

.search-submit {
  position: relative;
}
.search-submit::before {
  content: " ";
  position: absolute;
  background: url(../images/search.svg) no-repeat;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
}
.submenu_hover .menu-columns .column {
  padding-left: 39%;
  padding-right: 10%;
}

i::before {
  content: none !important;
}

.user-name-mobile {
  display: none;
}

@media (max-width: 1199.98px) {
  .bar-wrapper {
    display: block;
    position: relative;
    background: transparent;
    z-index: 9999;
    width: 22px;
    background-image: none;
    padding: 8px 11px;
    border: 1px solid transparent;
  }
  .bar-wrapper span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #222328;
    opacity: 1;
    left: 0;
    transition: transform 0.2s linear, top 0.3s linear, width 0.3s ease-in-out, opacity 0.5s ease-in-out;
  }
  .bar-wrapper span:first-child {
    top: 0;
  }
  .bar-wrapper span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .bar-wrapper span:nth-child(3) {
    bottom: 0;
  }
  body.menu-open header .bar-wrapper span:first-child {
    transform: rotate(45deg);
    top: 50%;
    width: 100%;
  }
  body.menu-open header .bar-wrapper span:nth-child(2) {
    width: 0;
    opacity: 0;
  }
  body.menu-open header .bar-wrapper span:last-child {
    transform: rotate(-45deg);
    bottom: unset;
    top: 50%;
  }
  body {
    overflow: hidden;
  }
  .menu-open .header_logo, .menu-open .quote-li, .menu-open .icon-link {
    display: none !important;
  }
  .menu-open .user-name-mobile {
    display: block;
  }
  .navbar-header {
    display: block;
    left: 15px;
  }
  .main_menu_mobile {
    padding: 10px 20px;
  }
  .main_menu_mobile .menu-main-menu-container ul li {
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    font-weight: 500;
    padding: 7px 0;
  }
  .main_menu_mobile .menu-main-menu-container ul li i {
    z-index: 10;
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .main_menu_mobile .menu-main-menu-container .sub-menu {
    display: none;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu {
    padding: 7px 0 !important;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu .submenu_hover {
    display: none;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu.active .submenu_hover {
    display: block;
    padding: 10px !important;
    background-color: #F1F1F1;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu.active .submenu_hover ul li {
    padding: 10px 0 !important;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu.active .submenu_hover ul li .fa-plus {
    display: none;
  }
  .main_menu_mobile .menu-main-menu-container .parent_menu.active .submenu_hover .all-products {
    margin: 10px 0 !important;
  }
  .main_menu_mobile .menu-main-menu-2-container ul li {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 0;
  }
  .header .main_menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff6ea;
  }
  .header .main_menu a {
    display: block;
    border-bottom: 1px solid #222328;
    padding: 10px;
  }
  .header .menu-main-menu-container {
    max-height: 0;
    overflow-y: auto;
    position: fixed;
    top: 45px;
    left: 0;
    background: #fff6ea;
    height: calc(100vh - 110px);
    transition: max-height 0.2s ease-out;
    width: 100%;
    z-index: 99;
  }
  .header .menu-main-menu-container #menu-main-menu {
    padding: 20px;
    padding-bottom: 5px;
  }
  .header .menu-main-menu-container #menu-main-menu > li > a {
    padding: 10px;
  }
  .header .main_menu-mb {
    right: 20px;
  }
  .header .main_menu-mb li {
    height: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .header .main_menu-mb li a {
    border: 2px solid;
    border-top: inherit;
    border-bottom: inherit;
    padding: 5px 8px;
    border-radius: 15px;
  }
  .header .main_menu-mb li a:hover, .header .main_menu-mb li a:focus {
    color: #ffffff;
    background-color: #665344;
  }
  .header ul {
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
  .header ul li {
    position: relative;
  }
  .header ul li i {
    z-index: 10;
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .header ul li i svg {
    pointer-events: none;
  }
  .header .submenu_hover,
  .header .sub-menu {
    max-height: 0;
    position: relative;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
  .header .submenu_hover ul,
  .header .sub-menu ul {
    padding: 0 0 0 15px;
  }
  .header .header_right .search_toggle {
    width: 100%;
    max-width: 100%;
  }
  .header .header_right .search_toggle > form {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .header .header_right .search_toggle > div {
    flex: 0 0 50px;
    max-width: 50px;
  }
}
@media (max-width: 767.98px) {
  .main_menu {
    min-height: unset;
  }
  .head_widget {
    font-size: 13px;
  }
  #close_button img {
    width: 12px;
  }
  .custom-logo-link img {
    max-height: 40px;
    width: auto;
  }
  .header_right {
    padding-top: 8px;
  }
  #quote-li {
    position: absolute;
    right: 15px;
    top: 2px;
  }
  #quote-li img.icon {
    width: 30px;
  }
  #quote-li #total-items {
    font-size: 10px;
    top: -13px;
    width: 15px;
    height: 15px;
  }
  div#n2-ss-3 .n2-ss-control-bullet {
    bottom: 115px !important;
  }
  header.affix #quote-li #total-items {
    top: -10px;
  }
  header.affix .submenu_hover {
    top: 0;
  }
  .menu-main-menu-container ul li {
    text-align: start !important;
  }
}
@media (max-width: 575.98px) {
  header .header::before {
    height: 1px;
  }
  .header_logo {
    padding-top: 2px;
  }
  .custom-logo-link img {
    max-height: 35px;
  }
  #quote-li-icon img {
    width: 30px;
  }
}
.cart-count {
  padding: 1px 7px;
  top: 0;
  left: 16px;
}

.main_menu_desktop #item-menu .menu-icon,
.main_menu_desktop #item-menu .close-icon {
  transition: opacity 0.3s ease; /* Adjust the duration and easing as needed */
}
.main_menu_desktop #item-menu .menu-icon {
  opacity: 1;
  display: block;
}
.main_menu_desktop #item-menu .close-icon {
  opacity: 0;
  display: none;
}
.main_menu_desktop #item-menu.show .menu-icon {
  opacity: 0;
  display: none;
}
.main_menu_desktop #item-menu.show .close-icon {
  opacity: 1;
  display: block;
}

.button_view_all img {
  padding: 0 0 0 20px;
}

.footer {
  font-size: 14px;
  color: #777777;
}
.footer li {
  margin-bottom: 1rem;
}
.footer li a {
  color: #ffffff;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}
.footer h3 {
  color: #222328;
  font-weight: 600;
  margin-bottom: 2rem;
  font-size: 1rem;
}
.footer p {
  color: #222328;
  font-size: 0.875rem;
  font-weight: 600;
}
.footer .copy-right p {
  color: #665244;
  font-size: 0.875rem;
}
.footer .footer-title {
  padding: 15px 0;
  border-bottom: 1px solid #665244;
}
.footer .footer-title p {
  color: #222328;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}
.footer .newsletter {
  text-align: center;
}
.footer .newsletter h3 {
  margin-bottom: 1rem;
}
.footer .newsletter input[type=email] {
  border: inherit;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  text-align: center;
  color: #ffffff;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  width: 100%;
}
.footer .newsletter input[type=email]::placeholder {
  color: #ffffff;
  font-size: 20px;
}
.footer .newsletter p:nth-child(3) {
  padding: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 29px;
}
.footer .newsletter p:nth-child(3) input[type=submit] {
  border: 2px solid;
  padding: 5px 20px;
  border-radius: 80px;
  font-weight: normal;
  color: #ffffff;
  background-color: transparent;
  transition: 0.6s;
  font-size: 0.875rem;
}
.footer .newsletter p:nth-child(3) input[type=submit]:hover, .footer .newsletter p:nth-child(3) input[type=submit]:focus {
  color: #ffffff;
  background-color: #222328;
  border: inherit;
}
.footer .contact a {
  color: #ffffff;
}
.footer .menufooter a {
  color: #222328;
  font-size: 1.3rem;
}
.footer .menufooter h3 {
  margin-bottom: 1.7rem;
}
.footer h4 {
  font-size: 14px;
  font-family: "SFProDisplay", sans-serif;
  font-weight: bold;
  margin-bottom: 23px;
  letter-spacing: 0;
  color: #777777;
}
.footer_logo figure {
  text-align: left;
}
.footer_logo ul {
  margin-bottom: 0;
}
.footer_logo ul li {
  padding-right: 20px;
  padding-bottom: 5px;
}
.footer_logo ul li img {
  max-height: 32px;
  width: auto !important;
}
.footer .map {
  height: 500px;
}
.footer .map .map_widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  opacity: 1;
  transition: 0.5s opacity ease-in-out;
}
.footer .map .map_widget.show_map {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s opacity ease-in-out;
}
.footer .map .overlay_title,
.footer .map .overlay_button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.footer .map .overlay_title {
  font-family: "SFProDisplay", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  top: 35%;
  font-size: 26px;
}
.footer .map .overlay_button {
  justify-content: center;
}
.footer .map .overlay_button a {
  width: 350px;
  padding: 15px 0 !important;
}
.footer .map .widget_media_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.footer .map .widget_media_image div {
  height: 100%;
  width: 100%;
}
.footer .map .widget_media_image figure {
  margin: 0;
  height: 100%;
  width: 100%;
}
.footer .map .widget_media_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1199.98px) {
  .footer .map .overlay_title {
    font-size: 22px;
  }
  .footer .map .overlay_button a {
    width: 300px;
    padding: 10px 0 !important;
  }
}

.mc4wp-form {
  padding-top: 10px;
  padding-bottom: 30px;
}
.mc4wp-form .mc4wp-form-fields {
  position: relative;
  width: 580px;
}
.mc4wp-form .mc4wp-form-fields input[type=email] {
  padding: 10px 15px;
  background-color: white;
  position: relative;
  width: 580px;
  height: 64px;
}
.mc4wp-form .mc4wp-form-fields button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  padding: 4px 10px;
  height: 64px;
}

#menu-footer-menu-1, #menu-footer-menu-2, #menu-footer-menu-3 {
  padding: 40px 30px;
  margin-top: 1rem;
}
#menu-footer-menu-1 li, #menu-footer-menu-2 li, #menu-footer-menu-3 li {
  margin-bottom: 10px;
}
#menu-footer-menu-1 li a, #menu-footer-menu-2 li a, #menu-footer-menu-3 li a {
  text-transform: uppercase;
  color: #222328;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 767.98px) {
  .footer h4 {
    margin-bottom: 12px;
  }
  .subscribe-form {
    border-right: 0 !important;
  }
  .mc4wp-form .mc4wp-form-fields {
    width: 100%;
  }
  .mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 10px 15px;
    background-color: white;
    width: 100%;
    height: 54px;
    color: #222328;
  }
  .mc4wp-form .mc4wp-form-fields button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    padding: 4px 10px;
    height: 54px;
  }
  .widget.widget_nav_menu:first-child {
    border-right: 1px solid #665244;
  }
  .footer-title p {
    font-size: 24px !important;
  }
  .subscribe-form {
    border-bottom: 1px solid #665244;
  }
}
#total-items-count,
#total-items {
  position: absolute;
  top: -20px;
  right: -8px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  background: #d81f26;
  border-radius: 50%;
  color: #ffffff;
  padding-top: 1px;
}

.mini-quote-dropdown {
  position: fixed;
  height: 100vh;
  width: 560px;
  max-width: 100%;
  right: -190vw;
  top: 0;
  z-index: 99999;
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  transition: 0.3s;
}
.mini-quote-dropdown.show {
  right: 0;
}
.mini-quote-dropdown.show .cart_module {
  position: relative;
  z-index: 999;
  height: 100%;
  background: #ffffff;
}
.mini-quote-dropdown ul li .img {
  width: 80px;
}
.mini-quote-dropdown ul li .img img {
  margin: 0 !important;
  width: 100%;
  height: inherit;
  float: none;
}
.mini-quote-dropdown ul li .text_ct {
  width: calc(100% - 80px);
  padding-left: 15px;
}
.mini-quote-dropdown ul li .tit {
  width: calc(100% - 160px);
}
.mini-quote-dropdown ul li .price {
  width: 160px;
}
.mini-quote-dropdown ul li .price img {
  width: 20px;
}

/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * all-round-gothic:
 *   - http://typekit.com/eulas/00000000000000003b9b3766
 *   - http://typekit.com/eulas/00000000000000003b9b3767
 *   - http://typekit.com/eulas/00000000000000003b9b376c
 *   - http://typekit.com/eulas/00000000000000003b9b376d
 * raleway:
 *   - http://typekit.com/eulas/00000000000000003b9b13b9
 *   - http://typekit.com/eulas/00000000000000003b9b13b3
 *   - http://typekit.com/eulas/00000000000000003b9b13b2
 *   - http://typekit.com/eulas/00000000000000003b9b13b8
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-23 05:50:06 UTC"}*/
@font-face {
  font-family: "SFProDisplay-Bold";
  src: url("root/fonts/SFPRODISPLAYBOLD.ttf") format("truetype"), url("root/fonts/SFPRODISPLAYBOLD.woff") format("woff"), url("root/fonts/SFPRODISPLAYBOLD.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay-lightItalic";
  src: url("root/fonts/SFPRODISPLAYLIGHTITALIC.ttf") format("truetype"), url("root/fonts/SFPRODISPLAYLIGHTITALIC.woff") format("woff"), url("root/fonts/SFPRODISPLAYLIGHTITALIC.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay-Regular";
  src: url("root/fonts/SFPRODISPLAYREGULAR.ttf") format("truetype"), url("root/fonts/SFPRODISPLAYREGULAR.woff") format("woff"), url("root/fonts/SFPRODISPLAYREGULAR.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("root/fonts/SFPRODISPLAYMEDIUM.ttf") format("truetype"), url("root/fonts/SFPRODISPLAYMEDIUM.woff") format("woff"), url("root/fonts/SFPRODISPLAYMEDIUM.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "SVN-Grand-Slang";
  src: url("root/fonts/SVN-GrandSlang-Roman.woff") format("woff"), url("root/fonts/SVN-GrandSlang-Roman.ttf") format("truetype"), url("root/fonts/SVN-GrandSlang-Roman.woff2") format("woff2");
  font-display: swap;
}
* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100%;
}
body .home {
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #222328;
  font-weight: bold;
}
a:hover, a:focus {
  color: #665344;
}

.ls-1 {
  letter-spacing: 1px;
}

figcaption {
  text-align: center;
}

blockquote,
em {
  font-family: var(--bs-font-sans-serif-lightItalic);
}

ul {
  padding: 0;
  list-style: none;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "SFProDisplay-Bold", sans-serif;
  color: #665344;
}
h6 a,
.h6 a,
h5 a,
.h5 a,
h4 a,
.h4 a,
h3 a,
.h3 a,
h2 a,
.h2 a,
h1 a,
.h1 a {
  color: #665344;
  transition: 0.3s;
}
h6 a:hover,
.h6 a:hover,
h5 a:hover,
.h5 a:hover,
h4 a:hover,
.h4 a:hover,
h3 a:hover,
.h3 a:hover,
h2 a:hover,
.h2 a:hover,
h1 a:hover,
.h1 a:hover {
  color: #665344;
}

video:focus {
  outline: none;
}

.pager-list .st-pagelink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pager-list .st-pagelink span,
.pager-list .st-pagelink a {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #222328;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SFProDisplay", sans-serif;
}
.pager-list .st-pagelink span {
  background: #222328;
  color: #ffffff;
}
.pager-list .st-pagelink a {
  color: #665344;
}
.pager-list .st-pagelink img {
  width: 20px;
}

.list_post_main {
  padding-top: 20px;
}
.list_post_main p {
  margin-bottom: 0;
}
.list_post_main .post_main_title span {
  color: #222328;
}
.list_post_main .post_main_title .left,
.list_post_main .post_main_title .right {
  text-align: center;
}
.list_post_main.center h2 {
  font-size: 1.5rem;
}
@media only screen and (max-width: 1440px) and (min-width: 992px) {
  .list_post_main.center h2 {
    font-size: 2.8rem !important;
  }
}
@media (max-width: 991.98px) {
  .list_post_main .img-about {
    min-height: 338px;
  }
}
.list_post_main.aboutuscenter h2 {
  font-size: 1.5rem;
}
@media only screen and (max-width: 1440px) and (min-width: 992px) {
  .list_post_main.aboutuscenter h2 {
    font-size: 2.8rem !important;
  }
}
.list_post_main sub,
.list_post_main sup {
  color: #222328;
  font-size: 0.5rem;
  font-weight: bold;
}
.list_post_main sup {
  top: -1em;
}
.list_post_main sub {
  bottom: -0.1em;
}

.btn-circle,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit,
.theme-milk-romance.woocommerce-page .btn-circle {
  height: 100%;
  max-height: 29px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-circle a,
.btn-circle input[type=submit],
.btn-circle button,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit a,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit input[type=submit],
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit button,
.theme-milk-romance.woocommerce-page .btn-circle a,
.theme-milk-romance.woocommerce-page .btn-circle input[type=submit],
.theme-milk-romance.woocommerce-page .btn-circle button {
  border: 2px solid;
  padding: 5px 20px !important;
  line-height: inherit;
  color: #222328 !important;
  background-color: transparent;
  border-radius: 80px;
  font-weight: normal;
}
.btn-circle a:hover, .btn-circle a:focus,
.btn-circle input[type=submit]:hover,
.btn-circle input[type=submit]:focus,
.btn-circle button:hover,
.btn-circle button:focus,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit a:hover,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit a:focus,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit input[type=submit]:hover,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit input[type=submit]:focus,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit button:hover,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit button:focus,
.theme-milk-romance.woocommerce-page .btn-circle a:hover,
.theme-milk-romance.woocommerce-page .btn-circle a:focus,
.theme-milk-romance.woocommerce-page .btn-circle input[type=submit]:hover,
.theme-milk-romance.woocommerce-page .btn-circle input[type=submit]:focus,
.theme-milk-romance.woocommerce-page .btn-circle button:hover,
.theme-milk-romance.woocommerce-page .btn-circle button:focus {
  color: #ffffff;
  background-color: #665344 !important;
}
.btn-circle input[type=submit],
.btn-circle button,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit input[type=submit],
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit button,
.theme-milk-romance.woocommerce-page .btn-circle input[type=submit],
.theme-milk-romance.woocommerce-page .btn-circle button {
  background-color: transparent;
  color: #222328;
  transition: 0.6s;
}
.btn-circle.update,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit.update,
.theme-milk-romance.woocommerce-page .btn-circle.update {
  justify-content: flex-end;
}
.btn-circle a,
.theme-milk-romance.woocommerce-page #review_form #respond .form-submit a,
.theme-milk-romance.woocommerce-page .btn-circle a {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

.theme-milk-romance.woocommerce-page .woocommerce-reward-points-text-container {
  border: 2px solid #222328;
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}
@media (max-width: 991.98px) {
  .theme-milk-romance.woocommerce-page .woocommerce-reward-points-text-container .woocommerce-reward-points-redemption-form-container {
    width: 100% !important;
    margin-top: 30px;
  }
  .theme-milk-romance.woocommerce-page .woocommerce-reward-points-text-container .woocommerce-reward-points-level-container {
    width: 35% !important;
  }
  .theme-milk-romance.woocommerce-page .woocommerce-reward-points-text-container .woocommerce-reward-points-earning-text-container {
    width: 65% !important;
  }
}
.theme-milk-romance.woocommerce-page .woocommerce-reward-points-redemption-form-inputs input[type=number] {
  font-size: 1.375rem;
  height: 60px;
  color: #222328 !important;
  border: inherit !important;
  border-bottom: 1px solid #222328 !important;
  margin-right: 20px !important;
}
.theme-milk-romance.woocommerce-page .woocommerce-reward-points-redemption-form-inputs input[type=number]::placeholder {
  color: #222328;
  opacity: 0.6;
}
.theme-milk-romance.woocommerce-page .woocommerce-reward-points-redemption-form-inputs a {
  border: 2px solid #222328;
  transition: 0.6s;
}
.theme-milk-romance.woocommerce-page .woocommerce-reward-points-redemption-form-inputs a:hover {
  background-color: #665344 !important;
  color: #ffffff !important;
  border-color: transparent;
}

.theme-milk-romance.sln-salon-page #sln-salon {
  margin: 0 auto;
}
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis {
  background-color: transparent !important;
}
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis button,
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis a,
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis input {
  color: #222328 !important;
  border: 1px solid #222328;
}
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis button:hover,
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis a:hover,
.theme-milk-romance.sln-salon-page #sln-salon .sln-btn--emphasis input:hover {
  color: #ffffff !important;
  background-color: #665344 !important;
  border-color: transparent;
}
.theme-milk-romance.sln-salon-page #sln-salon.sln-customcolors .sln-btn--borderonly:hover {
  color: #ffffff !important;
}
.btn-circle-white {
  max-height: 29px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-circle-white a {
  border: 2px solid;
  padding: 5px 20px;
  border-radius: 80px;
  font-weight: normal;
}
.btn-circle-white a:hover, .btn-circle-white a:focus {
  color: #ffffff;
  background-color: #665344;
}

.btn-primary, .footer .map .overlay_button a {
  color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  text-align: center;
  padding: 8px 10px 9px;
  border-radius: 0;
  transition: 0.3s;
  min-width: 200px;
  background-color: #000000;
  font-size: 18px;
}
.btn-primary:hover, .footer .map .overlay_button a:hover, .btn-primary:active, .footer .map .overlay_button a:active {
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  border: 1px solid #000000;
  box-shadow: none;
}
.btn-primary a, .footer .map .overlay_button a a {
  color: #ffffff;
}
.btn-primary:hover a, .footer .map .overlay_button a:hover a, .btn-primary:active a, .footer .map .overlay_button a:active a {
  color: #000000;
}

input:focus {
  outline: none;
}

.swiper-pagination {
  width: 100%;
  margin: 0;
  bottom: 25px !important;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: #ffffff;
  opacity: 0.5;
  margin: 0 3px;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
  height: 80px !important;
  width: 20px !important;
  background: rgba(36, 30, 32, 0.3);
  transform: translateY(-50%);
}
.swiper-button-prev:after,
.swiper-button-next:after {
  color: #ffffff;
}

.swiper-button-prev:focus {
  outline: none;
}

.swiper-button-next:focus {
  outline: none;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.overlay-cart {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: 0.3s;
}

select {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--bs-primary);
  background: var(--bs-white);
  color: var(--bs-body-color);
  padding: 0 15px 0 10px;
  margin-right: 0 !important;
  -webkit-appearance: none;
          appearance: none;
  border-radius: none;
}
select:focus-visible {
  outline: none;
}

.contact_form input[type=text],
.contact_form input[type=email],
.contact_form input[type=password],
.contact_form input[type=tel],
.contact_form select,
.contact_form textarea,
.contact_form .select2-selection,
.comment-form input[type=text],
.comment-form input[type=email],
.comment-form input[type=password],
.comment-form input[type=tel],
.comment-form select,
.comment-form textarea,
.comment-form .select2-selection,
.woocommerce-checkout input[type=text],
.woocommerce-checkout input[type=email],
.woocommerce-checkout input[type=password],
.woocommerce-checkout input[type=tel],
.woocommerce-checkout select,
.woocommerce-checkout textarea,
.woocommerce-checkout .select2-selection {
  width: 100%;
  font-size: 16px;
  padding: 6px;
  color: #222328;
  border: 1px solid #ebebeb;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 3px;
  box-shadow: none;
  height: 45px;
}
.contact_form input[type=text]:focus,
.contact_form input[type=email]:focus,
.contact_form input[type=password]:focus,
.contact_form input[type=tel]:focus,
.contact_form select:focus,
.contact_form textarea:focus,
.contact_form .select2-selection:focus,
.comment-form input[type=text]:focus,
.comment-form input[type=email]:focus,
.comment-form input[type=password]:focus,
.comment-form input[type=tel]:focus,
.comment-form select:focus,
.comment-form textarea:focus,
.comment-form .select2-selection:focus,
.woocommerce-checkout input[type=text]:focus,
.woocommerce-checkout input[type=email]:focus,
.woocommerce-checkout input[type=password]:focus,
.woocommerce-checkout input[type=tel]:focus,
.woocommerce-checkout select:focus,
.woocommerce-checkout textarea:focus,
.woocommerce-checkout .select2-selection:focus {
  outline: none;
}
.contact_form input[type=text]::placeholder,
.contact_form input[type=email]::placeholder,
.contact_form input[type=password]::placeholder,
.contact_form input[type=tel]::placeholder,
.contact_form select::placeholder,
.contact_form textarea::placeholder,
.contact_form .select2-selection::placeholder,
.comment-form input[type=text]::placeholder,
.comment-form input[type=email]::placeholder,
.comment-form input[type=password]::placeholder,
.comment-form input[type=tel]::placeholder,
.comment-form select::placeholder,
.comment-form textarea::placeholder,
.comment-form .select2-selection::placeholder,
.woocommerce-checkout input[type=text]::placeholder,
.woocommerce-checkout input[type=email]::placeholder,
.woocommerce-checkout input[type=password]::placeholder,
.woocommerce-checkout input[type=tel]::placeholder,
.woocommerce-checkout select::placeholder,
.woocommerce-checkout textarea::placeholder,
.woocommerce-checkout .select2-selection::placeholder {
  color: #4e4f53;
}
.contact_form input[type=text] option,
.contact_form input[type=email] option,
.contact_form input[type=password] option,
.contact_form input[type=tel] option,
.contact_form select option,
.contact_form textarea option,
.contact_form .select2-selection option,
.comment-form input[type=text] option,
.comment-form input[type=email] option,
.comment-form input[type=password] option,
.comment-form input[type=tel] option,
.comment-form select option,
.comment-form textarea option,
.comment-form .select2-selection option,
.woocommerce-checkout input[type=text] option,
.woocommerce-checkout input[type=email] option,
.woocommerce-checkout input[type=password] option,
.woocommerce-checkout input[type=tel] option,
.woocommerce-checkout select option,
.woocommerce-checkout textarea option,
.woocommerce-checkout .select2-selection option {
  color: #222328;
}
.contact_form input[type=text] .select2-selection__arrow,
.contact_form input[type=email] .select2-selection__arrow,
.contact_form input[type=password] .select2-selection__arrow,
.contact_form input[type=tel] .select2-selection__arrow,
.contact_form select .select2-selection__arrow,
.contact_form textarea .select2-selection__arrow,
.contact_form .select2-selection .select2-selection__arrow,
.comment-form input[type=text] .select2-selection__arrow,
.comment-form input[type=email] .select2-selection__arrow,
.comment-form input[type=password] .select2-selection__arrow,
.comment-form input[type=tel] .select2-selection__arrow,
.comment-form select .select2-selection__arrow,
.comment-form textarea .select2-selection__arrow,
.comment-form .select2-selection .select2-selection__arrow,
.woocommerce-checkout input[type=text] .select2-selection__arrow,
.woocommerce-checkout input[type=email] .select2-selection__arrow,
.woocommerce-checkout input[type=password] .select2-selection__arrow,
.woocommerce-checkout input[type=tel] .select2-selection__arrow,
.woocommerce-checkout select .select2-selection__arrow,
.woocommerce-checkout textarea .select2-selection__arrow,
.woocommerce-checkout .select2-selection .select2-selection__arrow {
  top: 25%;
  transform: translateY -50%;
}
.contact_form input[type=text] .select2-selection__rendered,
.contact_form input[type=email] .select2-selection__rendered,
.contact_form input[type=password] .select2-selection__rendered,
.contact_form input[type=tel] .select2-selection__rendered,
.contact_form select .select2-selection__rendered,
.contact_form textarea .select2-selection__rendered,
.contact_form .select2-selection .select2-selection__rendered,
.comment-form input[type=text] .select2-selection__rendered,
.comment-form input[type=email] .select2-selection__rendered,
.comment-form input[type=password] .select2-selection__rendered,
.comment-form input[type=tel] .select2-selection__rendered,
.comment-form select .select2-selection__rendered,
.comment-form textarea .select2-selection__rendered,
.comment-form .select2-selection .select2-selection__rendered,
.woocommerce-checkout input[type=text] .select2-selection__rendered,
.woocommerce-checkout input[type=email] .select2-selection__rendered,
.woocommerce-checkout input[type=password] .select2-selection__rendered,
.woocommerce-checkout input[type=tel] .select2-selection__rendered,
.woocommerce-checkout select .select2-selection__rendered,
.woocommerce-checkout textarea .select2-selection__rendered,
.woocommerce-checkout .select2-selection .select2-selection__rendered {
  line-height: inherit !important;
}
.contact_form label,
.comment-form label,
.woocommerce-checkout label {
  color: #4e4f53;
  font-size: 16px;
}
.contact_form .angle-down,
.comment-form .angle-down,
.woocommerce-checkout .angle-down {
  z-index: -1;
}
.contact_form .angle-down svg,
.comment-form .angle-down svg,
.woocommerce-checkout .angle-down svg {
  color: #222328;
}
.contact_form .woocommerce-checkout input[type=text],
.contact_form .woocommerce-checkout input[type=email],
.contact_form .woocommerce-checkout input[type=password],
.contact_form .woocommerce-checkout input[type=tel],
.contact_form .woocommerce-checkout select,
.contact_form .woocommerce-checkout textarea,
.contact_form .woocommerce-checkout .select2-selection,
.contact_form .woocommerce-checkout .select2-selection__rendered,
.comment-form .woocommerce-checkout input[type=text],
.comment-form .woocommerce-checkout input[type=email],
.comment-form .woocommerce-checkout input[type=password],
.comment-form .woocommerce-checkout input[type=tel],
.comment-form .woocommerce-checkout select,
.comment-form .woocommerce-checkout textarea,
.comment-form .woocommerce-checkout .select2-selection,
.comment-form .woocommerce-checkout .select2-selection__rendered,
.woocommerce-checkout .woocommerce-checkout input[type=text],
.woocommerce-checkout .woocommerce-checkout input[type=email],
.woocommerce-checkout .woocommerce-checkout input[type=password],
.woocommerce-checkout .woocommerce-checkout input[type=tel],
.woocommerce-checkout .woocommerce-checkout select,
.woocommerce-checkout .woocommerce-checkout textarea,
.woocommerce-checkout .woocommerce-checkout .select2-selection,
.woocommerce-checkout .woocommerce-checkout .select2-selection__rendered {
  color: #000000;
}
.contact_form .woocommerce-checkout input[type=text]::placeholder,
.contact_form .woocommerce-checkout input[type=email]::placeholder,
.contact_form .woocommerce-checkout input[type=password]::placeholder,
.contact_form .woocommerce-checkout input[type=tel]::placeholder,
.contact_form .woocommerce-checkout select::placeholder,
.contact_form .woocommerce-checkout textarea::placeholder,
.contact_form .woocommerce-checkout .select2-selection::placeholder,
.contact_form .woocommerce-checkout .select2-selection__rendered::placeholder,
.comment-form .woocommerce-checkout input[type=text]::placeholder,
.comment-form .woocommerce-checkout input[type=email]::placeholder,
.comment-form .woocommerce-checkout input[type=password]::placeholder,
.comment-form .woocommerce-checkout input[type=tel]::placeholder,
.comment-form .woocommerce-checkout select::placeholder,
.comment-form .woocommerce-checkout textarea::placeholder,
.comment-form .woocommerce-checkout .select2-selection::placeholder,
.comment-form .woocommerce-checkout .select2-selection__rendered::placeholder,
.woocommerce-checkout .woocommerce-checkout input[type=text]::placeholder,
.woocommerce-checkout .woocommerce-checkout input[type=email]::placeholder,
.woocommerce-checkout .woocommerce-checkout input[type=password]::placeholder,
.woocommerce-checkout .woocommerce-checkout input[type=tel]::placeholder,
.woocommerce-checkout .woocommerce-checkout select::placeholder,
.woocommerce-checkout .woocommerce-checkout textarea::placeholder,
.woocommerce-checkout .woocommerce-checkout .select2-selection::placeholder,
.woocommerce-checkout .woocommerce-checkout .select2-selection__rendered::placeholder {
  color: #000000;
}
.contact_form .woocommerce-billing-fields__field-wrapper,
.contact_form .woocommerce-additional-fields__field-wrapper,
.contact_form .woocommerce-shipping-fields__field-wrapper,
.contact_form .woocommerce-checkout-review-order-table,
.comment-form .woocommerce-billing-fields__field-wrapper,
.comment-form .woocommerce-additional-fields__field-wrapper,
.comment-form .woocommerce-shipping-fields__field-wrapper,
.comment-form .woocommerce-checkout-review-order-table,
.woocommerce-checkout .woocommerce-billing-fields__field-wrapper,
.woocommerce-checkout .woocommerce-additional-fields__field-wrapper,
.woocommerce-checkout .woocommerce-shipping-fields__field-wrapper,
.woocommerce-checkout .woocommerce-checkout-review-order-table {
  margin-bottom: 20px;
}
.contact_form #payment,
.comment-form #payment,
.woocommerce-checkout #payment {
  border: 1px solid #665344;
  border-radius: 10px;
  background-color: transparent;
}
.contact_form #payment input[type=radio],
.comment-form #payment input[type=radio],
.woocommerce-checkout #payment input[type=radio] {
  position: absolute;
  opacity: 0;
  margin: 0 1em 0 0;
}
.contact_form #payment input[type=radio] + label,
.comment-form #payment input[type=radio] + label,
.woocommerce-checkout #payment input[type=radio] + label {
  position: relative;
  padding-left: 25px;
  color: #000000;
}
.contact_form #payment input[type=radio] + label:before,
.comment-form #payment input[type=radio] + label:before,
.woocommerce-checkout #payment input[type=radio] + label:before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 17px;
  height: 17px;
  background: #ffffff;
  border: 1px solid #000000;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.contact_form #payment input[type=radio]:checked + label,
.comment-form #payment input[type=radio]:checked + label,
.woocommerce-checkout #payment input[type=radio]:checked + label {
  color: #222328;
}
.contact_form #payment input[type=radio]:checked + label::before,
.comment-form #payment input[type=radio]:checked + label::before,
.woocommerce-checkout #payment input[type=radio]:checked + label::before {
  border-color: #222328;
  background: #222328;
  box-shadow: inset 0 0 0 3px #ffffff;
}
.contact_form .col2-set h3,
.comment-form .col2-set h3,
.woocommerce-checkout .col2-set h3 {
  border-bottom: 1px solid #665344;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.contact_main .contact_form::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  height: 1px;
  background-color: #222328;
}
@media (max-width: 991.98px) {
  .contact_main .contact_form::before {
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  margin: 0 !important;
}

.show-password-input {
  display: none;
}

.woocommerce-breadcrumb a {
  font-family: "SFProDisplay", sans-serif;
  color: #000000 !important;
}

.product_img img.on {
  display: none;
}
.product_img a:hover img.off {
  display: none;
}
.product_img a:hover img.on {
  display: block;
}

.scroll_style {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_style ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}
.scroll_style ::-webkit-scrollbar {
  width: 7px;
  background-color: #f1f1f1;
}
.scroll_style ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #ebebeb;
  box-shadow: inset 0 0 6px transparent;
}

select {
  color: rgb(117, 117, 117);
}

#swiper-slider-blog .swiper-button-prev {
  left: -35px !important;
}
#swiper-slider-blog .swiper-button-next {
  right: -35px !important;
}
#swiper-slider-blog .swiper-slide img {
  min-height: 289px;
}
#swiper-slider-blog .swiper-button-prev,
#swiper-slider-blog .swiper-button-next {
  background-color: transparent !important;
  height: 40px !important;
  width: 10px !important;
  font-weight: bold;
}
#swiper-slider-blog .swiper-button-prev:after,
#swiper-slider-blog .swiper-button-next:after {
  color: #808080;
}

.main_banner img {
  min-height: 425px;
}

main {
  margin-top: 40px;
}
main.show {
  margin-top: 62px;
}
.afrfqbt_single_page,
.green_btn {
  display: inline-block;
  color: #ffffff;
  background: #3ea06b !important;
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 9px 10px 11px;
  border: 1px solid #3ea06b !important;
  transition: 0.3s;
  margin-bottom: 5px;
}
.afrfqbt_single_page:hover, .afrfqbt_single_page:focus,
.green_btn:hover,
.green_btn:focus {
  text-decoration: none;
  opacity: 0.8;
  outline: none;
  color: #ffffff;
}

.text-hover-none:hover {
  text-decoration: none !important;
}

.lmp_products_loading {
  display: none !important;
}

.woocommerce-breadcrumb {
  display: none;
  margin-bottom: 0 !important;
}

.pagination_main {
  position: relative;
}
.pagination_main .btn-primary, .pagination_main .footer .map .overlay_button a, .footer .map .overlay_button .pagination_main a {
  min-width: 168px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.mx-70 {
  max-width: 70%;
}

@media (max-width: 767.98px) {
  .container {
    max-width: 100% !important;
  }
}

@media (max-width: 1199.98px) {
  #swiper-slider-blog .swiper-slide img {
    min-height: 227px;
  }
}
@media (max-width: 991.98px) {
  .wp-block-media-text {
    grid-template-columns: 100% !important;
  }
  .wp-block-media-text .wp-block-media-text__content {
    grid-column: 1 !important;
    grid-row: 2 !important;
  }
  .wp-block-media-text .wp-block-media-text__media {
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
  .pagination_main .btn-primary, .pagination_main .footer .map .overlay_button a, .footer .map .overlay_button .pagination_main a {
    position: static;
    display: block;
    margin: 0 auto;
    transform: inherit;
  }
  .mx-70 {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  #swiper-slider-blog .swiper-button-prev {
    left: -10px !important;
  }
  #swiper-slider-blog .swiper-button-next {
    right: -10px !important;
  }
  .btn-circle a {
    height: 29px;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 70px;
  }
}
@media (max-width: 575.98px) {
  .head_widget {
    font-size: 11px;
  }
  .mini-quote-dropdown ul li .tit {
    width: 100%;
  }
  .mini-quote-dropdown ul li .cate,
  .mini-quote-dropdown ul li .price {
    font-size: 14px;
  }
  .mini-quote-dropdown ul li .price {
    width: 100%;
    text-align: left !important;
  }
  .mini-quote-dropdown ul li .text_ct {
    position: relative;
    padding-right: 10px;
  }
  .mini-quote-dropdown ul li .cart_dtl {
    position: absolute;
    right: -10px;
    top: 20px;
  }
}
.list {
  list-style: initial !important;
  padding-left: 2rem;
}
.list li {
  margin-bottom: 1rem;
}

.contact_block img {
  width: 100%;
  height: 420px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 991.98px) {
  .contact_title {
    font-size: 40px !important;
  }
  .contact_block {
    flex-wrap: wrap !important;
  }
  .contact_block .wp-block-column {
    flex-basis: unset !important;
  }
  .contact_block img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 991.98px) {
  .main__page--title {
    font-size: 40px !important;
  }
}

.list_post_main_content {
  position: relative;
}
.list_post_main_content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  height: 1px;
  background-color: #222328;
}
@media (max-width: 991.98px) {
  .list_post_main_content::before {
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.list_post_main_content .about-list h2 {
  position: relative;
}
.list_post_main_content .about-list h2::after {
  content: "|";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 1.5rem;
}
.list_post_main_content .about-list h2:last-child::after {
  content: inherit;
}

.module-img {
  display: flex;
}

.column_item {
  width: 20%;
  transition: 0.6s;
  margin-right: 10px;
  transition: 0.6s;
}
.column_item:last-child {
  margin-right: 0px;
}
.column_item img {
  height: 100%;
}
@media (max-width: 991.98px) {
  .column_item img {
    min-height: 255px;
  }
}
.column_item .item_content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.column_item .item_content h2 {
  font-size: 0.625rem;
  transition: 0.6s;
}
.column_item .item_content h5 {
  font-size: 0.375rem;
  transition: 0.6s;
}
.column_item .btn-circle-white {
  display: none;
}

.column_item.active {
  width: 100%;
  transition: 0.6s;
}
.column_item.active .item_content {
  top: inherit;
  transition: 0.6s;
  bottom: 0;
  transform: translate(-50%, 0%);
}
.column_item.active .item_content h2 {
  font-size: 1.5rem;
  color: #ffffff;
}
.column_item.active .item_content h5 {
  font-size: 0.625rem;
  color: #ffffff;
}
.column_item.active .btn-circle-white {
  display: flex;
}
.column_item.active .btn-circle-white a {
  color: #ffffff;
}
.column_item.active .btn-circle-white a:hover {
  border-color: transparent;
}

.block-center .column_item {
  width: 12%;
  transition: 0.6s;
  margin-right: 10px;
}
@media (max-width: 991.98px) {
  .block-center .column_item {
    overflow: hidden;
    width: 100%;
  }
  .block-center .column_item img {
    min-height: inherit;
    max-height: 56px;
    -o-object-position: center;
       object-position: center;
  }
}
.block-center .column_item.active {
  width: 100%;
}
@media (max-width: 991.98px) {
  .block-center .column_item.active {
    overflow: hidden;
    height: 100%;
  }
  .block-center .column_item.active img {
    min-height: 388px;
    max-height: inherit;
  }
}

@media (max-width: 991.98px) {
  .min-h388 {
    min-height: 388px;
  }
  .max-h388 {
    max-height: 388px;
  }
}
.list_post_main .block-center .active {
  position: relative;
}
.list_post_main .block-center .active img.hover-img {
  opacity: 1;
  position: absolute;
  opacity: 0;
  transition: opacity 0.6s;
  top: 0;
  left: 0;
}
.list_post_main .block-center .active img.hover-img.show {
  transition: opacity 0.6s;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .list_post_main .frist-img {
    min-height: 217px;
  }
}

.homepageslider {
  height: 100vh;
}
.homepageslider .angle-down {
  position: absolute;
  left: 50%;
  bottom: 40px;
  color: #ffffff;
  font-size: 35px;
  transform: translateX(-50%);
}
.homepageslider #n2-ss-3 .n2-ss-slide {
  height: 100vh !important;
}
.homepageslider .n2-ss-slider .n2-ss-slide-background-image picture img {
  height: 100vh !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.homepageslider .n2-ss-slider .order-mobile .n2-ss-button-container {
  max-height: 29px;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.homepageslider .n2-ss-slider .order-mobile .n2-ss-button-container a {
  border: 2px solid;
  padding: 5px 20px !important;
  border-radius: 80px;
  font-weight: normal;
}
.homepageslider .n2-ss-slider .order-mobile .n2-ss-button-container a:hover, .homepageslider .n2-ss-slider .order-mobile .n2-ss-button-container a:focus {
  color: #ffffff;
  background-color: #665344;
}

.list_post_content_bottom .content p {
  color: #222328;
}

.slider-products .swiper-slide img {
  -o-object-fit: cover;
     object-fit: cover;
}
.slider-products .swiper-slide .content_des {
  color: #222328;
}
.slider-products .swiper-button-prev,
.slider-products .swiper-button-next {
  border-radius: 50%;
  border: 1px solid #222328;
  background: transparent;
  width: 1.625rem !important;
  height: 1.625rem !important;
  transition: 0.6s;
}
.slider-products .swiper-button-prev::after,
.slider-products .swiper-button-next::after {
  color: #222328;
  font-size: 0.625rem !important;
}
.slider-products .swiper-button-prev:hover,
.slider-products .swiper-button-next:hover {
  background-color: #665344;
  border: 0px;
}
.slider-products .swiper-button-prev:hover::after,
.slider-products .swiper-button-next:hover::after {
  color: #ffffff;
}
.slider-products .swiper-button-prev {
  left: -15px !important;
}
.slider-products .swiper-button-next {
  right: -15px !important;
}

.menus {
  transition: 0.6s;
  color: #222328;
  position: relative;
}
.menus h2 {
  color: #222328;
}
.menus h3 {
  color: #222328;
}
.menus::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  height: 1px;
  background-color: #222328;
}
@media (max-width: 991.98px) {
  .menus::before {
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.menus:last-child::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  height: 1px;
  background-color: #222328;
}
@media (max-width: 991.98px) {
  .menus:last-child::after {
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.menus .bills {
  max-height: 0;
  overflow: hidden;
  transition: 0.6s;
  opacity: 0;
}
.menus.active .angle-down svg {
  transform: rotate(180deg);
}
.menus.active .bills {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .post_main_title h2 {
    text-align: center;
  }
}
.theme-milk-romance.woocommerce #review_form #respond .comment-form-rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.theme-milk-romance.woocommerce #review_form #respond .comment-form-rating .stars {
  margin-bottom: 0;
}
.theme-milk-romance.woocommerce #review_form #respond .comment-form-rating .stars a {
  font-size: 0.875rem;
  margin-top: 10px;
}
.theme-milk-romance.woocommerce #review_form #respond .comment-form-rating label {
  margin-right: 10px;
}

.swiper-slider-product img {
  min-height: 500px;
  max-height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
}

.swiper-slider-thumb .swiper-slide {
  height: 80px !important;
}
.swiper-slider-thumb img {
  min-height: 80px;
  max-height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
  opacity: 0.5;
}
.swiper-slider-thumb .swiper-slide-thumb-active img {
  opacity: 1;
}

.add-to-cart-wrapper .quantity_wrapper .plus,
.add-to-cart-wrapper .quantity_wrapper .minus {
  color: #222328;
  font-weight: bold;
  cursor: pointer;
}
.add-to-cart-wrapper .quantity_wrapper .quantity input {
  border: inherit;
  font-size: 24px;
  padding: 0;
}
.add-to-cart-wrapper button {
  background-color: transparent;
  color: #222328;
  transition: 0.6s;
}

.price .amount {
  color: #222328;
}

.product-category-tab {
  cursor: pointer;
}
.product-category-tab a.active {
  background-color: #222328;
  color: #ffffff !important;
}

.product-container .product_item .product_img img {
  width: 100%;
  height: 416px;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-container .product_item .icon-card {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
     object-fit: cover;
}

.btn-circle {
  flex-wrap: wrap;
}
.btn-circle a.add_to_cart_button {
  content: " ";
  background-color: transparent !important;
}
.btn-circle a.add_to_cart_button:hover, .btn-circle a.add_to_cart_button:focus {
  background-color: transparent !important;
}
.btn-circle a.add_to_cart_button::before {
  content: " ";
  position: absolute;
  background: url(../images/cart.svg) no-repeat;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
}
.btn-circle a.add_to_cart_button::after {
  top: 7px !important;
  right: 5px !important;
}

.product-name .img {
  padding-right: 20px;
  width: 112px;
  height: 112px;
  -o-object-fit: cover;
     object-fit: cover;
}

.mh-300 {
  max-height: 150px;
}

@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  .font-laptop {
    --ssfont-scale: 0.65 !important;
  }
}
ul.slick-dots {
  bottom: -40px !important;
}
ul.slick-dots li.slick-active button {
  border-radius: 5px;
  width: 30px;
  height: 5px;
  background-color: #222328;
  margin: 0 -10px;
}
ul.slick-dots li {
  margin: 0 10px;
}
ul.slick-dots li button {
  border-radius: 100%;
  width: 10px;
  height: 5px;
  background-color: #4e4f53;
}
ul.slick-dots li button::before {
  content: "";
}

.news .slick-slide {
  height: 630px;
  min-height: 630px;
}
.news .slick-slide > div {
  height: 100%;
}

.single-product .woocommerce-message {
  display: none;
}
.single-product .tabs .tab-links li {
  display: inline-block;
  margin-right: 10px;
}
.single-product .tabs .tab-links li a {
  text-decoration: none;
  color: #222328;
}
.single-product .tabs .tab-links li a:hover {
  color: #222328;
  border-bottom: 1px solid #222328;
  padding-bottom: 5px;
}
.single-product .tabs .tab-links li a.active {
  padding-bottom: 5px;
  border-bottom: 1px solid #222328;
}
.single-product .tabs .tab-content .tab {
  display: none;
}
.single-product .tabs .tab-content .tab.active {
  display: block;
  animation: slideIn 1.3s ease;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.products-detail-content {
  padding: 0 20px;
}
.products-detail-content .product_title {
  order: 1;
}
.products-detail-content .tabs {
  order: 2;
}
.products-detail-content .cart {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-detail-content .cart .afrfqbt_single_page {
  background-color: #222328 !important;
  border-color: #222328 !important;
}
.products-detail-content .cart .qty {
  width: 20px;
  padding: 10px 0;
  background: transparent;
}

.quantity-buttons {
  background: rgba(40, 40, 70, 0.1);
}
.quantity-buttons .quantity-plus,
.quantity-buttons .quantity-minus {
  background: transparent;
  padding: 0 20px;
  color: #4e4f53;
}
.quantity-buttons .qty {
  width: 25px !important;
  padding: 10px 0;
  background: transparent;
}

.slider-catalog .catalog-item .even-class {
  width: 100%;
  height: 486px;
  -o-object-fit: cover;
     object-fit: cover;
}
.slider-catalog .catalog-item .odd-class {
  width: 100%;
  height: 402px;
  -o-object-fit: cover;
     object-fit: cover;
}

.product-news-swiper .product-item-new .thumbnail img {
  width: 312px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.product-item-related .thumbnail img {
  width: 312px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.our-cataloges {
  margin: 0 2px;
}

.contact-form .wpcf7-form .wpcf7-form-control {
  background-color: transparent !important;
  border-bottom: 1px;
  border-color: #ffffff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
}
.contact-form .wpcf7-form .wpcf7-tel,
.contact-form .wpcf7-form .wpcf7-text,
.contact-form .wpcf7-form .wpcf7-email,
.contact-form .wpcf7-form .wpcf7-textarea {
  width: 100%;
}
.contact-form .wpcf7-form .wpcf7-textarea {
  height: 100px;
}
.contact-form .wpcf7-form .wpcf7-form-control.wpcf7-submit {
  border-bottom: 0;
  color: #ffffff;
  padding: 10px 20px;
  position: relative;
}
.contact-form .wpcf7-form .wpcf7-spinner {
  display: none !important;
}
.contact-form .wpcf7-form .button-contact img {
  cursor: pointer;
}
.contact-form .wpcf7-form .wpcf7-response-output {
  color: #ffffff;
  border-color: #d81f26;
}

.news_main .post-thumbnail img {
  width: 100%;
  height: 484px;
  -o-object-fit: cover;
     object-fit: cover;
}
.news_main .entry-summary p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 1%;
  -webkit-line-clamp: 3;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.news_detail .thumbnail img {
  width: 100%;
  height: 760px;
  -o-object-fit: cover;
     object-fit: cover;
}
.news_detail .related-content-news {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 1%;
  -webkit-line-clamp: 3;
  height: 75px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.news_detail .related-img-news {
  width: 100%;
  height: 485px;
  -o-object-fit: cover;
     object-fit: cover;
}

.slick-dots li:nth-child(n+6) {
  display: none;
}

.case_projects_detail .thumbnail img {
  width: 100%;
  height: 760px;
  -o-object-fit: cover;
     object-fit: cover;
}

.registration .woocommerce-privacy-policy-text {
  display: none;
}

.woocommerce-privacy-policy-text p {
  font-size: 16px;
}

.chosen-container.chosen-container-single {
  width: auto !important;
  margin: 10px 15px;
}
.chosen-container.chosen-container-single .chosen-single {
  border: none;
  border-radius: 0;
}

.woof_container_inner h4 {
  display: none;
}

.woof_redraw_zone {
  display: flex;
}

.page-privacy-policy h2 {
  font-size: 32px;
  font-weight: 600;
  color: #222328;
  margin-bottom: 20px;
}
.page-privacy-policy h3 strong {
  font-weight: 600;
  font-size: 20px;
  color: #222328;
}
.page-privacy-policy p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.woocommerce-billing-fields__field-wrapper .form-row-first,
.woocommerce-billing-fields__field-wrapper .form-row-last {
  float: none !important;
  width: 100% !important;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #222328;
  border: 3px solid white;
  border-radius: 50%;
}

.woof_submit_search_form_container, .woof_products_top_panel {
  display: none !important;
}

/* Định dạng checkbox */
input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Định dạng dấu check màu trắng */
input[type=checkbox]:checked::before {
  content: "✓"; /* Unicode của dấu check */
  display: block;
  text-align: center;
  line-height: 12px; /* Căn giữa dấu check */
  color: #fff; /* Màu trắng */
  font-size: 16px;
  position: absolute;
  top: 0;
}

/* Định dạng khi hover */
input[type=checkbox]:hover {
  background-color: #333; /* Màu nền hover */
}

/* Định dạng khi được chọn */
input[type=checkbox]:checked {
  background-color: #000; /* Màu nền khi được chọn */
}

.btn-circle .single_add_to_cart_button {
  padding: 10px 20px !important;
  background: #222328 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}
.btn-circle .single_add_to_cart_button:hover {
  background: #222328 !important;
}

.page-template-template-cart .woocommerce-message {
  display: none !important;
}

.cart-pdf-button {
  display: none !important;
}

@media (max-width: 991.98px) {
  .news-category-tabs ul {
    justify-content: center !important;
  }
  .news-category-tabs ul li a {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .news-category-tabs ul li a.active {
    font-weight: 600 !important;
  }
}